import { useContext } from "react";
import { CwcContext } from "../provider";

export function useCwcChat() {
  const {
    resetSession,
    changeSession,
    toggleWindowState,
    customerData,
    user,
    messages,
    sendMessage,
    setChatWindowOpen,
    setAudioContext,
    status,
    ready,
    options,
    startSolution,
    segment,
    setSegment,
    language,
    cartDevicesInsurance,
    cartLocked,
    botApiUrl,
    labelTranslations,
    confirmSolutionData,
    clearConfirmSolutionData,
    publishStartSolution,
    skipAskingForConfirm,
    hasWelcomeMessage
  } = useContext(CwcContext);

  const copyToClipboard = () => {
    console.error("copyToClipboard - Not implemented!");
  };

  return {
    sendMessage,
    setChatWindowOpen,
    setAudioContext,
    messages,
    resetSession,
    changeSession,
    toggleWindowState,
    customerData,
    user,
    copyToClipboard,
    status,
    ready,
    options,
    startSolution,
    segment,
    setSegment,
    language,
    cartDevicesInsurance,
    cartLocked,
    botApiUrl,
    labelTranslations,
    confirmSolutionData,
    clearConfirmSolutionData,
    publishStartSolution,
    skipAskingForConfirm,
    hasWelcomeMessage
  };
}
