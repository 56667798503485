import React, { useEffect, useState } from "react";

import { useCwcChat, useCwcIframe, useAudio, useSender } from "cwc-provider";
import Widget from "components/Widget";
import axios from "axios";

const ADMIN_API_BASE_URL =
  window?.["app_env"].REACT_APP_ADMIN_API_URL || "http://127.0.0.1:8000";

const ChatWidget = () => {
  const sender = useSender();
  const {
    sendMessage,
    messages,
    ready,
    customerData,
    setAudioContext,
  } = useCwcChat();
  const { isLoaded, load } = useAudio();

  useEffect(() => {
    if (customerData.message && ready)
      sendMessage({
        type: "text",
        text: customerData.message,
        metadata: {},
        actions: [],
      });
  }, [ready]);

  useEffect(() => {
    sender.focus();
    if (!isLoaded) load();
  }, []);

  const {
    toggleParentWindow,
    options: {
      exclude_launch,
      // text_to_speech,
      isMobile,
      color_support,
      recent_message_threshold,
      other_styles,
      other_styles_chat_collapsed,
      message_placeholder,
    },
  } = useCwcIframe();

  const toggleParentWindowAndSetStyle = (state = null) => {
    if (!state) {
      const style = document.createElement("style");
      style.setAttribute("id", "collapsediv");
      if (other_styles_chat_collapsed)
        setTimeout(() => {
          Object.keys(other_styles_chat_collapsed).map(
            (className) =>
              (style.innerText += `\n${className} ${other_styles_chat_collapsed[className]}\n`)
          );
          document.head.append(style);
        }, 300);
    } else {
      const style = document.getElementById("collapsediv");
      if (style)
        setTimeout(() => {
          style.remove();
        });
    }
    toggleParentWindow(state);
  };
  useEffect(() => {
    const style = document.createElement("style");
    if (other_styles)
      Object.keys(other_styles).map(
        (className) =>
          (style.innerText += `\n${className} ${other_styles[className]}\n`)
      );
    document.head.append(style);
    if (!exclude_launch) {
      const styleCollapse = document.createElement("style");
      styleCollapse.setAttribute("id", "collapsediv");
      if (other_styles_chat_collapsed)
        Object.keys(other_styles_chat_collapsed).map(
          (className) =>
            (styleCollapse.innerText += `\n${className} ${other_styles_chat_collapsed[className]}\n`)
        );
      document.head.append(styleCollapse);
    }
    return () => {
      document.head.removeChild(style);
      const styleCollapse = document.getElementById("collapsediv");
      if (styleCollapse) document.head.removeChild(style);
    };
  }, []);

  const [screenMode, setScreenMode] = useState(isMobile ? "mobile" : "large");
  // const [registeredMessageNumber, setRegisteredMessageNumber] = useState(
  //   messages.length
  // );
  const [labels, setLabels] = useState([]);

  const getLabels = async () => {
    const response = await axios.get(`${ADMIN_API_BASE_URL}/api/v1/labels`);
    const labels = response.data;
    setLabels(labels.map((l) => ({ value: l.name })));
  };

  useEffect(() => {
    window.addEventListener("message", function (params) {
      if (params.data.event == "windowResize")
        setScreenMode(params.data.args.isMobile == true ? "mobile" : "large");
    });
    getLabels();
  }, []);

  // useEffect(() => {
  // const newMessages = messages
  //   .slice(registeredMessageNumber)
  //   .filter((message) => message.text && !message.text.includes("https://"));
  // const synth = window.speechSynthesis;
  // const voices = synth.getVoices();
  // if (text_to_speech && newMessages.length) {
  //   newMessages.map((message) => {
  //     if (message.role === "appMaker") {
  //       const utterThis = new SpeechSynthesisUtterance(message.text);
  //       utterThis.voice =
  //         voices.find(
  //           (voice) =>
  //             voice.name === "Microsoft Mark - English (United States)"
  //         ) ||
  //         voices.find((voice) => voice.name === "Samantha") ||
  //         voices.find((voice) => voice.name === "Google UK english Male") ||
  //         null;
  //       synth.speak(utterThis);
  //     }
  //     return 0;
  //   });
  // }

  //   setRegisteredMessageNumber(messages.length);
  // }, [messages]);

  const handleNewUserMessage = (newMessage, inputNodeId = null) => {
    sendMessage({
      type: "text",
      text: newMessage,
      input_node_id: inputNodeId,
      metadata: {},
      actions: [],
    });
  };

  const handlePostbackMessage = (payload) => {
    sendMessage({
      type: "postback",
      payload: payload,
      text: payload,
      metadata: {},
      actions: [],
    });
  };

  const handleQuickButtonClicked = () => {};
  const handleSubmit = () => {};

  return (
    <Widget
      title="MindTitan"
      titleRight={
        screenMode == "mobile" && !exclude_launch ? (
          <a
            href="#"
            onClick={() => {
              toggleParentWindowAndSetStyle();
              if (!isLoaded) load();
            }}
            className="icon-link"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke={color_support || "#000000"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke={color_support || "#000000"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
        ) : null
      }
      colors={{
        brandColor: "#ffffff",
        accentColor: "#D33643",
        secondaryAccentColor: "#D33643",
      }}
      screenMode={screenMode}
      toggleParentWindow={toggleParentWindowAndSetStyle}
      hideLauncher={exclude_launch}
      subtitle="Mindtitan virtual assitant"
      titleAvatar={require("./assets/mt.png")}
      senderPlaceHolder={message_placeholder || "Enter text..."}
      handleNewUserMessage={handleNewUserMessage}
      handlePostbackMessage={handlePostbackMessage}
      handleQuickButtonClicked={handleQuickButtonClicked}
      imagePreview
      handleSubmit={handleSubmit}
      messages={messages}
      dissableInput={false}
      recentMessageThreshold={recent_message_threshold}
      labels={labels}
      setAudioContext={setAudioContext}
    />
  );
};

export default ChatWidget;
