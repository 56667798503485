import React from "react";

import { createNewCarousel } from "utils/messages";
import { MESSAGE_SENDER } from "AppConstants";
import { createNewMessage, createNewTable } from "utils/messages";

export const getCarouselComponent = (message, showTimeStamp, sendMessage) => {
  const newElement = createNewCarousel(
    message.text,
    message.role === "appMaker"
      ? MESSAGE_SENDER.RESPONSE
      : MESSAGE_SENDER.CLIENT,
    message.items,
    `msg-${message.time}`
  );
  const ComponentToRender = newElement.component;
  return (
    <ComponentToRender
      carousel={newElement}
      showTimeStamp={showTimeStamp}
      sendMessage={sendMessage}
    />
  );
};

export const getTableComponent = (message, showTimeStamp) => {
  const tableMessage = JSON.parse(message.text);
  const newElement = createNewTable(
    message.role === "appMaker"
      ? MESSAGE_SENDER.RESPONSE
      : MESSAGE_SENDER.CLIENT,
    tableMessage.tableDef,
    tableMessage.colDef,
    tableMessage.data,
    `msg-${message.time}`
  );
  const ComponentToRender = newElement.component;
  return <ComponentToRender table={newElement} showTimeStamp={showTimeStamp} />;
};

export const getMessageComponent = (
  message,
  showTimeStamp,
  sendMessage,
  recentMessageThreshold,
  displayImage,
  sharpCorner
) => {
  const newElement = createNewMessage(
    message.text,
    message.type,
    message.isLastMessage,
    message.role === "appMaker"
      ? MESSAGE_SENDER.RESPONSE
      : MESSAGE_SENDER.CLIENT,
    message.actions,
    parseInt(message?.metadata?.task_last_modified || 0, 10),
    `msg-${message.time}`,
    message.message_helpdesk_state,
    message.message_client_state
  );
  const ComponentToRender = newElement.component;
  return (
    <ComponentToRender
      message={newElement}
      showTimeStamp={showTimeStamp}
      sendMessage={sendMessage}
      recentMessageThreshold={recentMessageThreshold}
      displayImage={displayImage}
      sharpCorner={sharpCorner}
    />
  );
};
