import React, { useEffect, useState } from "react";

import { useCwcChat, useCwcIframe, useAudio } from "cwc-provider";
import Widget from "components/Widget";
import { addLuminance } from "utils/colors";
let CUSTOM_CSS = window?.["settings_env"]?.["CUSTOM_CSS"];

const ChatWidget = () => {
  const {
    sendMessage,
    messages,
    ready,
    customerData,
    setAudioContext,
  } = useCwcChat();
  const { isLoaded, load } = useAudio();

  useEffect(() => {
    if (customerData.message && ready)
      sendMessage({
        type: "text",
        text: customerData.message,
        metadata: {},
        actions: [],
      });
  }, [ready]);

  let {
    displayImage,
    toggleParentWindow,
    sendOptions,
    setNotSeenMessageCount,
    options: iframeOption,
    dialogIsOpen,
  } = useCwcIframe();

  useEffect(() => {
    const notSeenCount = messages.reduceRight(
      (acc, el) => {
        if (
          el.message_client_state !== "seen" &&
          el.role === "appMaker" &&
          !acc.stopCounting
        ) {
          acc.count += 1;
        } else {
          acc.stopCounting = true;
        }
        return acc;
      },
      { count: 0, stopCounting: false }
    ).count;
    setNotSeenMessageCount(notSeenCount, messages);
  }, [messages]);

  CUSTOM_CSS =
    iframeOption?.custom_css !== undefined
      ? iframeOption?.custom_css
      : CUSTOM_CSS;
  iframeOption.other_styles = {
    ".rcw-conversation-container": `{
          border-radius: 4px;
      }`,
    ".rcw-header": `{
          visibility: visible;
          height: 56px;
          min-height: unset !important;
          max-height: unset !important;
          display: flex !important;
          justify-content: center;
          padding: 0px 24px !important;
      }`,
    "rcw-messages-scrollable-container": `{
      height: 100% !important;
    }`,
    ".rcw-title": `{
          padding: 0px !important;
      }`,
    ".chat-web-sdk-btn": `{
          height: 56px;
          width: 56px;
          box-shadow: unset;
      }`,
    ".chat-web-close-sdk-btn": `{
          height: 56px;
          width: 56px;
      }`,
    ...(iframeOption.other_styles || {}),
  };
  iframeOption.other_styles_chat_collapsed = {
    ".ant-select": `{
      visibility: hidden !important;
    }`,
    input: `{
      visibility: hidden !important;
    }`,
    textarea: `{
      display: none !important;
    }`,
    ".rcw-send": `{
      visibility: hidden !important;
    }`,
    ".rcw-header": `{
      visibility: hidden !important;
    }`,
    ".rcw-message-container": `{
      visibility: hidden !important;
    }`,
    ".rcw-messages-container": `{
      visibility: hidden !important;
    }`,
    ".quick-buttons-container": `{
      visibility: hidden !important;
    }`,
    ".rcw-conversation-container": `{
      visibility: hidden !important;
    }`,
    ...(iframeOption.other_styles_chat_collapsed || {}),
  };
  iframeOption.custom_bubble_style = {
    "z-index": 100000000000,
    border: "none",
    height: "calc(100% - 80px - 15px - 80px)",
    position: "fixed",
    right: "16px",
    width: "100%",
    bottom: "8px",
    "max-width": "376px",
    opacity: 1,
    transition: "bottom 0.6s, opacity 0.8s",
    ...(iframeOption.custom_bubble_style || {}),
  };
  iframeOption.custom_bubble_style_mobile = {
    "z-index": 100000000000,
    border: "none",
    height: "100%",
    position: "fixed",
    width: "100%",
    right: "0px",
    opacity: 1,
    bottom: "0px",
    transition: "bottom 0.6s, opacity 0.8s",
    "max-width": "100%",
    ...(iframeOption.custom_bubble_style_mobile || {}),
  };
  iframeOption.showChatLauncherStyle = {
    "z-index": 100000000000,
    height: "64px",
    width: "64px",
    position: "fixed",
    right: "24px",
    bottom: "16px",
    cursor: "pointer",
    opacity: 1,
    border: "none",
    transition: "opacity 0.8s",
    ...(iframeOption.showChatLauncherStyle || {}),
  };

  const toggleParentWindowAndSetStyle = (state = null) => {
    // if (!state) {
    //   const style = document.createElement("style");
    //   style.setAttribute("id", "collapsediv");
    //   if (iframeOption.other_styles_chat_collapsed)
    //     setTimeout(() => {
    //       Object.keys(iframeOption.other_styles_chat_collapsed).map(
    //         (className) =>
    //           (style.innerText += `\n${className} ${iframeOption.other_styles_chat_collapsed[className]}\n`)
    //       );
    //       document.head.append(style);
    //     }, 300);
    // } else {
    //   const style = document.getElementById("collapsediv");
    //   if (style)
    //     setTimeout(() => {
    //       style.remove();
    //     });
    // }
    toggleParentWindow(state);
  };
  const options = {
    title: "Custom",
    ...iframeOption,
  };

  useEffect(() => {
    sendOptions(iframeOption);
  }, [iframeOption]);

  const [screenMode, setScreenMode] = useState(
    options.isMobile ? "mobile" : "large"
  );
  useEffect(() => {
    window.addEventListener("message", function (params) {
      if (params.data.event == "windowResize")
        setScreenMode(params.data.args.isMobile == true ? "mobile" : "large");
    });
  }, []);

  useEffect(() => {
    const style = document.createElement("style");
    style.setAttribute("id", "widget-tmp-styles");
    style.innerText = `
      .rcw-send-icon {
        fill: ${options.color_support};
      }
      .rcw-title {
        color: ${options.color_support};
      }
      .rcw-send {
        background-color: ${options.color_bar};
      }
      .rcw-send:hover {
        background-color: ${addLuminance(options.color_bar || "#ffffff", 10)};
      }
      .rcw-header {
        background-color: ${options.color_bar || "white"} !important;
        ${options.color_bar ? "color: white !important;" : ""}
      }
      .rcw-client .rcw-message-wrapper {
        background-color: ${options.color_bar} !important;
        color: ${options.color_support};
      }
      .rcw-response .rcw-message-action-button {
        background-color: ${options.color_support} !important;
        color: ${options.color_bar};
        border: 2px solid ${options.color_bar} !important;
      } 
      .rcw-response .rcw-message-action-button:hover {
        color: ${addLuminance(options.color_bar || "#ffffff", 10)} !important;
        border: 2px solid ${addLuminance(
          options.color_bar || "#ffffff",
          10
        )} !important;
      }
      .rcw-response .rcw-message-action-button-clicked {
        background-color: ${options.color_bar} !important;
        color: ${options.color_support};
        border: 2px solid ${options.color_bar} !important;
      }
      .rcw-response .rcw-message-action-button-clicked:hover {
        opacity: 1 !important;
        cursor: not-allowed;
        color: ${options.color_support} !important;
      }
      .rcw-response .rcw-message-wrapper {
        background-color: ${options.color_user} !important;
        color: '#000';
      }
      `;
    if (options.other_styles)
      Object.keys(options.other_styles).map(
        (className) =>
          (style.innerText += `\n${className} ${options.other_styles[className]}\n`)
      );
    style.innerText += CUSTOM_CSS;
    document.head.append(style);
    if (!options.exclude_launch) {
      const styleCollapse = document.createElement("style");
      styleCollapse.setAttribute("id", "collapsediv");
      if (options.other_styles_chat_collapsed)
        Object.keys(options.other_styles_chat_collapsed).map(
          (className) =>
            (styleCollapse.innerText += `\n${className} ${options.other_styles_chat_collapsed[className]}\n`)
        );
      // document.head.append(styleCollapse);
    }
    return () => {
      document.head.removeChild(style);
      const styleCollapse = document.getElementById("collapsediv");
      if (styleCollapse) document.head.removeChild(style);
    };
  }, []);

  const handleNewUserMessage = (newMessage, inputNodeId = null) => {
    sendMessage({
      type: "text",
      text: newMessage,
      input_node_id: inputNodeId,
      metadata: {},
      actions: [],
    });
  };

  const handleFileMessage = (file) => {
    sendMessage({
      type: "file",
      file,
      metadata: {},
      actions: [],
    });
  };

  const handlePostbackMessage = (payload) => {
    sendMessage({
      type: "postback",
      payload: payload,
      text: payload,
      metadata: {},
      actions: [],
    });
  };

  const handleQuickButtonClicked = () => {};
  const handleSubmit = () => {};
  return (
    <Widget
      title={options.title}
      hideLauncher={options.exclude_launch}
      toggleParentWindow={toggleParentWindowAndSetStyle}
      setAudioContext={setAudioContext}
      dialogIsOpen={dialogIsOpen}
      displayImage={displayImage}
      colors={{
        brandColor: options.color_bar,
        accentColor: options.color_support,
        secondaryAccentColor: options.color_user,
      }}
      screenMode={screenMode}
      titleRight={
        screenMode == "mobile" && !options.exclude_launch ? (
          <a
            href="#"
            onClick={() => {
              toggleParentWindowAndSetStyle();
              if (!isLoaded) {
                load();
              }
            }}
            className="icon-link"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke={options?.color_support || "#000000"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke={options?.color_support || "#000000"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
        ) : null
      }
      titleAvatar={
        options.icon_path ? require("" + options.icon_path) : options.icon_url
      }
      senderPlaceHolder={options.message_placeholder || "Enter text..."}
      handleNewUserMessage={handleNewUserMessage}
      handleFileMessage={handleFileMessage}
      handlePostbackMessage={handlePostbackMessage}
      handleQuickButtonClicked={handleQuickButtonClicked}
      imagePreview
      handleSubmit={handleSubmit}
      messages={options.demo_messages || messages}
      dissableInput={!!options.demo_messages}
      recentMessageThreshold={options.recent_message_threshold}
      labels={[]}
    />
  );
};

export default ChatWidget;
