import React, { useRef } from "react";

import { AudioProvider } from "./audioProvider";
import { SenderProvider } from "./senderProvider";
import { useMqttClient } from "./hooks/mqtt";
export const CwcContext = React.createContext(null);

const CWC_MQTT_URL =
  window?.["app_env"].REACT_APP_CWC_MQTT_URL ||
  "wss://annika.elisa.ee:8070/mqtt";

type PartialMessage = { message_uid: string; message_id: string };

function filterMessages(messages: PartialMessage[]) {
  const filteredMessages: PartialMessage[] = [];

  let i = -1;
  messages.forEach((message) => {
    if (i === -1) {
      i += 1;
      filteredMessages.push(message);
    } else {
      if (
        message.message_id !== filteredMessages[i].message_id ||
        message.message_uid !== filteredMessages[i].message_uid
      ) {
        i += 1;
        filteredMessages.push(message);
      }
    }
  });

  return filteredMessages;
}

export const CwcProviderInner = ({
  children,
  botApiUrl,
  customer,
  options,
}) => {
  const chatWindowOpen = useRef(options?.exclude_launch || false);
  const notifySeenMessagesRef = useRef(null);
  const setChatWindowOpen = (x) => {
    chatWindowOpen.current = x;
    if (x) notifySeenMessagesRef.current?.();
  };

  // const { mqtt, status } = useMqttClient({
  //   url: CWC_MQTT_URL,
  //   user: {},
  //   customer,
  // });

  const {
    messages,
    publish,
    sendFile,
    ready,
    customerData,
    changeSession,
    resetSession,
    user,
    notifySeenMessages,
    setAudioContext,
    startSolution,
    segment,
    setSegment,
    cartDevicesInsurance,
    cartLocked,
    labelTranslations,
    confirmSolutionData,
    clearConfirmSolutionData,
    publishStartSolution,
    skipAskingForConfirm,
    hasWelcomeMessage
  } = useMqttClient({
    url: CWC_MQTT_URL,
    user: {},
    limit: 100,
    reverse: false,
    customer,
    chatWindowOpen,
    defaultLabelLanguage: options?.default_label_language,
    cachingEnabled: options?.cachingEnabled,
  });
  notifySeenMessagesRef.current = notifySeenMessages;
  function sendMessage(message) {
    if (message.type === "file") {
      sendFile({
        role: "appUser", // provide default value but allow to override
        ...message,
        authorId: user?.user_id,
        received: Math.floor(Date.now() / 1000),
      });
    } else {
      publish({
        role: "appUser", // provide default value but allow to override
        ...message,
        authorId: user?.user_id,
        received: Math.floor(Date.now() / 1000),
      });
    }
  }
  return (
    <CwcContext.Provider
      value={{
        startSolution,
        resetSession,
        changeSession,
        customerData,
        user,
        messages: filterMessages(messages),
        sendMessage,
        setChatWindowOpen,
        setAudioContext,
        status,
        ready,
        options,
        toggleWindowState: setChatWindowOpen,
        segment,
        setSegment,
        language: options.default_label_language || options.languages,
        cartDevicesInsurance,
        cartLocked,
        botApiUrl,
        labelTranslations,
        confirmSolutionData,
        clearConfirmSolutionData,
        publishStartSolution,
        skipAskingForConfirm,
        hasWelcomeMessage
      }}
    >
      {children}
    </CwcContext.Provider>
  );
};

export const CwcProvider = ({
  children,
  botApiUrl,
  customer,
  logLevel,
  options,
}) => {
  const props = {
    botApiUrl,
    customer,
    logLevel,
    options,
  };

  return (
    <SenderProvider>
      <AudioProvider>
        <CwcProviderInner {...props}>{children}</CwcProviderInner>
      </AudioProvider>
    </SenderProvider>
  );
};
