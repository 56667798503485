import React, { useState } from "react";
import format from "date-fns/format";
import markdownIt from "markdown-it";
import markdownItSup from "markdown-it-sup";
import markdownItSanitizer from "markdown-it-sanitizer";
import markdownItLinkAttributes from "markdown-it-link-attributes";
import markdownItFontawesome from "./markdownItFontawesome";

import { Message } from "types";
import { constructActions, parseMessageLinks } from "../../messageActions";

import "./styles.scss";
import { AnyFunction } from "utils/types";
import { saveAs } from "file-saver";
import { HelpdeskState } from "./HelpdeskState";

const AttachmentIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width="22"
    height="22"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 20 20"
  >
    <path
      fill={color}
      d="M5.602 19.8c-1.293 0-2.504-.555-3.378-1.44c-1.695-1.716-2.167-4.711.209-7.116l9.748-9.87c.988-1 2.245-1.387 3.448-1.06c1.183.32 2.151 1.301 2.468 2.498c.322 1.22-.059 2.493-1.046 3.493l-9.323 9.44c-.532.539-1.134.858-1.738.922c-.599.064-1.17-.13-1.57-.535c-.724-.736-.828-2.117.378-3.337l6.548-6.63c.269-.272.705-.272.974 0s.269.714 0 .986l-6.549 6.631c-.566.572-.618 1.119-.377 1.364c.106.106.266.155.451.134c.283-.029.606-.216.909-.521l9.323-9.439c.64-.648.885-1.41.69-2.145a2.162 2.162 0 0 0-1.493-1.513c-.726-.197-1.48.052-2.12.7l-9.748 9.87c-1.816 1.839-1.381 3.956-.209 5.143c1.173 1.187 3.262 1.629 5.079-.212l9.748-9.87a.683.683 0 0 1 .974 0a.704.704 0 0 1 0 .987L9.25 18.15c-1.149 1.162-2.436 1.65-3.648 1.65z"
    />
  </svg>
);
const IMAGE_REGEX = /^!\[\]\(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)\)$/gi; //eslint-disable-line
const ATTACHMENT_REGEX = /^!!\[(.*?)\]\(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)\)$/gi; //eslint-disable-line

type Props = {
  message: Message;
  showTimeStamp: boolean;
  sendMessage: AnyFunction;
  recentMessageThreshold: number;
  displayImage?: AnyFunction;
  sharpCorner?: "top" | "bottom" | "both";
};

const cornerCss = {
  "top": "rcw-border-top-left-radius-0",
  "bottom": "rcw-border-bottom-left-radius-0",
  "both": "rcw-border-top-left-radius-0 rcw-border-bottom-left-radius-0"
}

export default function ({
  message,
  showTimeStamp,
  sendMessage,
  recentMessageThreshold,
  displayImage,
  sharpCorner
}: Props) {
  const [showImage, setShowImage] = useState(true);
  const { parsedActions, outputText } = parseMessageLinks(message);
  const setLocation = (uri: string) => {
    window.parent.location.href = uri;
  };
  const sanitizedHTML = markdownIt({
    linkify: true,
  })
    .use(markdownItSup)
    .use(markdownItFontawesome)
    .use(markdownItSanitizer)
    .use(markdownItLinkAttributes, {
      attrs: { target: "_blank", rel: "noopener" },
    })
    .render(`${message?.type == "error" ? "Message: " : ""}${outputText}`);

  const isRecentMessage =
    Math.floor(Date.now() / 1000) - message.taskLastModified <=
    recentMessageThreshold;

  const isResponseImage = () => {
    if (message.sender === "client" || !outputText) return false;
    const imageRegex = new RegExp(IMAGE_REGEX);
    const isImage = imageRegex.test(outputText);
    if (isImage) {
      const imageUrl = outputText.match(/\(([^)]+)\)/)[1];
      return imageUrl;
    }
  };

  const isResponseAttachment = () => {
    if (message.sender === "client" || !outputText) return false;
    const attachmentRegex = new RegExp(ATTACHMENT_REGEX);
    const isAttachment = attachmentRegex.test(outputText);
    if (isAttachment) {
      const fileName = outputText.match(/\[(.*?)\]/)[1];
      const fileUrl = outputText.match(/\(([^)]+)\)/)[1];
      return {
        fileName: fileName || fileUrl,
        fileUrl,
      };
    }
  };

  return (
    <div className="rcw-message-container">
      <div className="rcw-message">
        <div className={`rcw-${message.sender}`}>
          {outputText &&
          message?.type !== "file" &&
          !isResponseImage() &&
          !isResponseAttachment() ? (
            <div
              className={`rcw-message-wrapper ${
                message?.type == "error" ? "-error" : ""
              } ${isRecentMessage ? "rcw-message-wrapper-recent" : ""} ${sharpCorner ? cornerCss[sharpCorner] : ""}`}
            >
              {message?.type == "error" ? (
                <div className="rcw-error-heading">Error!</div>
              ) : null}
              <div
                dir="auto"
                className="rcw-message-text"
                dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
              />
            </div>
          ) : message?.type === "file" ? (
            <>
              {showImage &&
              Array.isArray(message.actions) &&
              message.actions.length > 0 ? (
                message?.actions
                  ?.filter((action) => action.type === "file")
                  ?.map((action) => (
                    <img
                      key={action.filename}
                      onError={() => setShowImage(false)}
                      onClick={() => displayImage(action.url)}
                      style={{
                        width: "80%",
                        cursor: "zoom-in",
                        alignSelf: message.sender === "client" && "flex-end",
                        marginBottom: 5,
                      }}
                      src={action.url}
                    />
                  ))
              ) : (
                <div className="rcw-message-wrapper">
                  {Array.isArray(message.actions) &&
                  message.actions.length > 0 ? (
                    <a
                      style={{ color: "unset", textDecoration: "underline" }}
                      className="rcw-message-text"
                      onClick={() => saveAs(message.actions[0].url, outputText)}
                    >
                      <div
                        style={{
                          position: "relative",
                          top: 5,
                          display: "inline",
                        }}
                      >
                        <AttachmentIcon color={message.sender === "client" ? "#fff" : "#000"} />
                      </div>
                      <div style={{ display: "inline", marginLeft: 4 }}>
                        {outputText}
                      </div>
                    </a>
                  ) : (
                    <div className="rcw-message-text">{outputText}</div>
                  )}
                </div>
              )}
            </>
          ) : isResponseImage() ? (
            <img
              onClick={() => displayImage(isResponseImage())}
              src={isResponseImage()}
              style={{
                width: "80%",
                cursor: "zoom-in",
                marginBottom: 5,
              }}
            />
          ) : isResponseAttachment() ? (
            <div className="rcw-message-wrapper">
              <a
                style={{ color: "unset", textDecoration: "underline" }}
                className="rcw-message-text"
                onClick={() =>
                  saveAs(
                    (isResponseAttachment() as any)?.fileUrl,
                    (isResponseAttachment() as any)?.fileName
                  )
                }
              >
                <div
                  style={{
                    position: "relative",
                    top: 5,
                    display: "inline",
                  }}
                >
                  <AttachmentIcon color={message.sender === "client" ? "#fff" : "#000"} />
                </div>
                <div style={{ display: "inline", marginLeft: 4 }}>
                  {(isResponseAttachment() as any)?.fileName}
                </div>
              </a>
            </div>
          ) : null}
          <div
            className={
              message?.actions?.length == 2 &&
              message?.actions?.every((action) => action?.text?.length <= 10)
                ? "rcw-stretched-action-btn-wrapper"
                : "rcw-list-action-btn-wrapper"
            }
          >
            {parsedActions && parsedActions.length > 0
              ? constructActions(parsedActions, sendMessage, setLocation)
              : ""}
            {message.isLastMessage &&
            message.actions &&
            message.actions.length > 0
              ? constructActions(message.actions, sendMessage, setLocation)
              : ""}
          </div>
          {showTimeStamp && (
            <span className="rcw-timestamp">
              {format(message.timestamp, "hh:mm")}
            </span>
          )}
        </div>
      </div>
      <HelpdeskState message={message} />
    </div>
  );
}
