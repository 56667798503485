import React, { useEffect, useState } from "react";
import { AnyFunction } from "utils/types";

import WidgetLayout from "./layout";

type Props = {
  title: string;
  titleAvatar?: string;
  titleCenter?: any;
  titleRight?: any;
  hideLauncher?: any;
  colors?: any;
  subtitle?: string;
  senderPlaceHolder?: string;
  profileAvatar?: string;
  showCloseButton?: boolean;
  fullScreenMode?: boolean;
  autofocus?: boolean;
  setAudioContext?: AnyFunction;
  customLauncher?: AnyFunction;
  handleNewUserMessage?: AnyFunction;
  handleFileMessage?: AnyFunction;
  handlePostbackMessage?: AnyFunction;
  handleQuickButtonClicked?: AnyFunction;
  toggleParentWindow?: AnyFunction;
  displayImage?: AnyFunction;
  handleTextInputChange?: (event: any) => void;
  chatId?: string;
  launcherOpenLabel?: string;
  launcherCloseLabel?: string;
  sendButtonAlt?: string;
  showTimeStamp?: boolean;
  imagePreview?: boolean;
  zoomStep?: number;
  handleSubmit?: AnyFunction;
  messages: any[];
  showChat?: boolean;
  visible?: boolean;
  dissableInput?: boolean;
  screenMode?: any;
  recentMessageThreshold?: number;
  labels?: any[];
  dialogIsOpen?: boolean;
};

export function Widget({
  title,
  titleCenter,
  titleRight,
  titleAvatar,
  colors,
  hideLauncher,
  subtitle,
  senderPlaceHolder,
  profileAvatar,
  toggleParentWindow,
  displayImage,
  showCloseButton,
  fullScreenMode,
  autofocus,
  setAudioContext,
  customLauncher,
  handleNewUserMessage,
  handleFileMessage,
  handlePostbackMessage,
  handleQuickButtonClicked,
  handleTextInputChange,
  chatId,
  launcherOpenLabel,
  launcherCloseLabel,
  sendButtonAlt,
  showTimeStamp,
  imagePreview,
  zoomStep,
  handleSubmit,
  messages,
  showChat,
  visible,
  dissableInput,
  screenMode,
  recentMessageThreshold,
  labels,
  dialogIsOpen,
}: Props) {
  // const dispatch = useDispatch();
  const [chatOpen, setChatOpen] = useState(false);

  useEffect(() => {
    if (dialogIsOpen) {
      setTimeout(() => {
        setTimeout(() => setChatOpen(true), 300);
        toggleParentWindow(true);
      }, 600);
    }
  }, []);

  const toggleConversation = () => {
    // dispatch(toggleChat());
  };

  const handleMessageSubmit = (event) => {
    event.preventDefault();
    if (event.currentTarget) {
      event.currentTarget.disabled = true;
      event.currentTarget.className += " rcw-message-action-button-clicked";
    }

    const message = event.target.message;
    const userInput = message.value;
    const messageType = message.type || "text";

    if (messageType !== "file" && !userInput.trim()) {
      return;
    }

    handleSubmit?.(userInput);
    if (messageType === "postback") {
      handlePostbackMessage(message.payload);
    } else if (messageType === "file") {
      handleFileMessage(message.file);
    } else {
      handleNewUserMessage(userInput, message.inputNodeId);
    }

    event.target.message.value = "";
  };

  const onQuickButtonClicked = (event, value) => {
    event.preventDefault();
    handleQuickButtonClicked?.(value);
  };

  return (
    <div>
      <WidgetLayout
        onToggleConversation={toggleConversation}
        onSendMessage={handleMessageSubmit}
        setAudioContext={setAudioContext}
        onQuickButtonClicked={onQuickButtonClicked}
        toggleParentWindow={toggleParentWindow}
        displayImage={displayImage}
        title={title}
        hideLauncher={hideLauncher}
        titleAvatar={titleAvatar}
        titleCenter={titleCenter}
        titleRight={titleRight}
        subtitle={subtitle}
        senderPlaceHolder={senderPlaceHolder}
        profileAvatar={profileAvatar}
        showCloseButton={showCloseButton}
        fullScreenMode={fullScreenMode}
        autofocus={autofocus}
        customLauncher={customLauncher}
        onTextInputChange={handleTextInputChange}
        chatId={chatId}
        launcherOpenLabel={launcherOpenLabel}
        launcherCloseLabel={launcherCloseLabel}
        sendButtonAlt={sendButtonAlt}
        showTimeStamp={showTimeStamp}
        imagePreview={imagePreview}
        zoomStep={zoomStep}
        messages={messages}
        showChat={showChat}
        visible={visible}
        colors={colors}
        chatOpen={chatOpen}
        setChatOpen={setChatOpen}
        dissableInput={dissableInput}
        screenMode={screenMode}
        recentMessageThreshold={recentMessageThreshold}
        labels={labels}
      />
    </div>
  );
}

export default Widget;
