import React, { useEffect, useState } from "react";
import { addLuminance } from "utils/colors";
import { useCustomer, useQuery } from "cwc-provider";
let CUSTOM_CSS = window?.["settings_env"]?.["CUSTOM_CSS"];

export default function Launcher() {
  const search = useQuery();
  const [options, setOptions] = useState<any>({});
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [btnHovered, setBtnHovered] = useState(false);
  const [parseCustomer, setCustomer] = useCustomer();
  const [isWindowOpen, setIsWindowOpen] = useState(
    JSON.parse(search.get("options") || "{}").cachingEnabled &&
      parseCustomer()?.dialogIsOpen
  );
  const origin = new URLSearchParams(window.location.search).get("origin");
  const sessionId = new URLSearchParams(window.location.search).get(
    "sessionId"
  );

  const titleAvatar = options.icon_path
    ? require("" + options.icon_path)
    : options.icon_url;
  const colors = {
    brandColor: options.color_bar,
    accentColor: options.color_support_launcher || options.color_support,
    secondaryAccentColor: options.color_user,
  };
  const currentBrandColor = btnHovered
    ? addLuminance(colors?.brandColor || "#000000", 10)
    : colors?.brandColor;
  const [notSeenCount, setNotSeenCount] = useState(0);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerText = CUSTOM_CSS;
    document.head.append(style);

    window.addEventListener(
      "message",
      (e) => {
        const { event, args } = e.data;
        if (event === "set_options") {
          console.log("setting options", args.options);
          setOptions(args.options);
          setOptionsLoaded(true);
        } else if (event === "set_not_seen_messages_count") {
          setNotSeenCount(args.count);
        } else if (event === "chat_window_state_changed") {
          const customer = parseCustomer();
          setCustomer({
            ...customer,
            dialogIsOpen: args.isWindowOpen,
            dialogWasEverOpen:
              args.isWindowOpen === true ? true : customer?.dialogWasEverOpen,
          });
          setIsWindowOpen(args.isWindowOpen);
        }
      },
      false
    );
  }, []);
  return (
    <>
      {optionsLoaded ? (
        <div
          onClick={() => {
            window.parent.postMessage(
              {
                sessionId,
                event: "toggleLauncher",
              },
              origin
            );
          }}
          className={
            isWindowOpen ? "chat-web-close-sdk-btn" : "chat-web-sdk-btn"
          }
          onMouseEnter={() => setBtnHovered(true)}
          onMouseLeave={() => setBtnHovered(false)}
          style={{
            background:
              !isWindowOpen && titleAvatar
                ? `url(${titleAvatar}) no-repeat center center, ${currentBrandColor}`
                : currentBrandColor,
          }}
        >
          {isWindowOpen ? (
            <svg
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke={colors?.accentColor || "#fff"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke={colors?.accentColor || "#fff"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <div>
              {notSeenCount ? (
                <div className="chat-web-sdk-btn-messages">{notSeenCount}</div>
              ) : null}

              {!titleAvatar ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
                    stroke={colors.accentColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : null}
            </div>
          )}
        </div>
      ) : (
        <div />
      )}
    </>
  );
}
