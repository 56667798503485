import React, { useEffect, useState } from "react";
import { useCustomer } from "cwc-provider";
const SHOW_MESSAGE_PREVIEW =
  window?.["app_env"].REACT_APP_SHOW_MESSAGE_PREVIEW === "true";
const MESSAGE_PREVIEW_FADE_OUT_DELAY = parseInt(
  window?.["app_env"].MESSAGE_PREVIEW_FADE_OUT_DELAY || "15"
);
let CUSTOM_CSS = window?.["settings_env"]?.["CUSTOM_CSS"];

function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

export default function Notification() {
  if (!SHOW_MESSAGE_PREVIEW) return <div />;
  const [title, setTitle] = useState("");
  const [messagesNotSeen, setMessagesNotSeen] = useState([]);
  const [messages, setMessages] = useState([]);
  const [notificationClosed, setNotificationClosed] = useState(true);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [isMessagePreviewVisible, setIsMessagePreviewVisible] = useState<any>(
    false
  );

  useEffect(() => {
    const origin = new URLSearchParams(window.location.search).get("origin");
    const sessionId = new URLSearchParams(window.location.search).get(
      "sessionId"
    );

    window.parent.postMessage(
      {
        sessionId,
        event: "isMessagePreviewVisible",
        args: {
          isMessagePreviewVisible: isNumber(isMessagePreviewVisible)
            ? false
            : isMessagePreviewVisible,
          delay: isNumber(isMessagePreviewVisible)
            ? isMessagePreviewVisible
            : 0,
        },
      },
      origin
    );
  }, [isMessagePreviewVisible]);

  useEffect(() => {
    setIsMessagePreviewVisible(
      !(messagesNotSeen.length === 0 || notificationClosed)
    );
    if (messagesNotSeen[messagesNotSeen.length - 1]?.isLastCwcMessage) {
      setTimeout(() => {
        setIsMessagePreviewVisible(MESSAGE_PREVIEW_FADE_OUT_DELAY);
      }, 500);
    }
  }, [messagesNotSeen.length, notificationClosed]);

  const reset = () => {
    setIsMessagePreviewVisible(0.2);
  };
  const activate = () => {
    setNotificationClosed(false);
  };

  const handleNotification = async () => {
    const temp = messages.filter(
      (m) =>
        m.message_client_state !== "seen" &&
        m.role === "appMaker" &&
        m.text &&
        m.type !== "flow_ended"
    );
    if (temp[temp.length - 1]?.ignoreNotification) return;
    let delayActivate = false;
    if (
      temp.length &&
      messagesNotSeen.length &&
      temp[temp.length - 1]?.message_id !==
        messagesNotSeen[messagesNotSeen.length - 1]?.message_id
    ) {
      reset();
      await new Promise((r) => setTimeout(r, 400));
      activate();
      delayActivate = true;
    }

    if ((!messagesNotSeen.length || notificationClosed) && temp.length) {
      activate();
      if (temp[temp.length - 1]?.isLastCwcMessage) delayActivate = true;
    } else if (messagesNotSeen.length && !temp.length) {
      reset();
    } else if (temp[temp.length - 1]?.isLastCwcMessage) {
      if (delayActivate) setTimeout(() => activate(), 400);
      else activate();
    }

    setMessagesNotSeen(temp);
  };

  useEffect(() => {
    handleNotification().then(() => {});
  }, [messages]);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerText = CUSTOM_CSS;
    document.head.append(style);

    window.addEventListener(
      "message",
      (e) => {
        const { event, args } = e.data;
        if (event === "messages") {
          setMessages(args.messages);
        } else if (event === "set_options") {
          setTitle(args.options.title);
          setOptionsLoaded(true);
        }
      },
      false
    );
  }, []);

  const parseCustomer = useCustomer()[0];
  if (parseCustomer().dialogIsOpen) return <div />;

  return (
    <>
      {optionsLoaded ? (
        <div
          id="notification-container"
          style={{
            visibility: "visible",
          }}
        >
          <div id="notification" className="animation-default">
            <div id="notification-header">
              <p id="notification-title">{title}</p>
              <div className="close" onClick={() => reset()}>
                <svg
                  className="close-btn"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 1.03223L0.978027 16"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M0.978027 1.03223L16 16"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <p id="notification-text">
              {messagesNotSeen[messagesNotSeen.length - 1]?.text}
            </p>
          </div>
        </div>
      ) : (
        <div />
      )}
    </>
  );
}
