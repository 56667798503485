import React from "react";
import log from "loglevel";

import { CwcProvider, useQuery } from "cwc-provider";
import ChatWidget from "./widget";
import {v4 as uuidv4} from 'uuid';

export default function App() {
  const search = useQuery();
  const logLevel = search.get("log-level") as log.LogLevelDesc;
  const newUserUUID = uuidv4();
  console.log("FULL NAME in cwc: ", search.get("customerFullName"));
  const customer = {
    customerId: search.get("customerId"),
    code: search.get("code") || newUserUUID.toString(),
    secure: search.get("secure") || "false",
    segment: search.get("segment") || "Anonymous",
    customer_proxy: search.get("customer_proxy") || "",
    customerFullName:
        search.get("customerFullName") || "client ".concat(newUserUUID.toString().split('-')[0]),
    message: search.get("message") || "",
    // started_from: search.get("started_from"),
  };

  return (
    <CwcProvider
      botApiUrl=""
      customer={customer}
      logLevel={logLevel}
      options={JSON.parse(search.get("options") || "{}")}
    >
      <ChatWidget />
    </CwcProvider>
  );
}
