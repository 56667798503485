import React from "react";

import { QuickButton } from "types";
import "./styles.scss";

type Props = {
  button: QuickButton;
  onQuickButtonClicked: (event: any, value: string | number) => any;
};

export default function ({ button, onQuickButtonClicked }: Props) {
  return (
    <button
      className="quick-button"
      onClick={(event) => onQuickButtonClicked(event, button.value)}
    >
      {button.label}
    </button>
  );
}
