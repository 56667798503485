import React from "react";
import { Message } from "types";
import SendingImage from "./message_state_imgs/sending";
import SentImage from "./message_state_imgs/sent";
import DeliveredImage from "./message_state_imgs/delivered";
import SeenImage from "./message_state_imgs/seen";
import FailedImage from "./message_state_imgs/failed";

type Props = {
    message: Message;
};
export const HelpdeskState = ({ message }: Props) => {
  if (message.sender !== 'client') {
    return null;
  }
  return (
    <div className="rcw-message-img-wrapper">
      {message.message_helpdesk_state === "unsent" ? (
        <SendingImage />
      ) : message.message_helpdesk_state === "sent" ? (
        <SentImage />
      ) : message.message_helpdesk_state === "delivered" ? (
        <DeliveredImage />
      ) : message.message_helpdesk_state === "seen" ? (
        <SeenImage />
      ) : (
        <FailedImage />
      )}
    </div>
  );
};