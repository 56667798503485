import React, { useContext, useRef, useState, MutableRefObject } from "react";

const audiosQueueGlobal: string[] = [];
Audio.prototype.play = (function (play) {
  return function (...rest) {
    const promise = play.apply(this, rest);
    if (promise !== undefined) {
      promise.catch((e) => {
        // Autoplay was prevented. This is optional, but add a button to start playing.
        console.log("autoplay prevented", e);
        audiosQueueGlobal.shift();
      });
    }
  };
})(Audio.prototype.play);

const audio = new Audio();
audio.play.bind(audio)
const notificationAudio = new Audio();
notificationAudio.play.bind(notificationAudio)

audio.addEventListener("ended", function () {
  audiosQueueGlobal.shift();
  if (audiosQueueGlobal.length) {
    audio.src = audiosQueueGlobal[0];
    audio.play();
  }
});

export const AudioContext = React.createContext({
  audio,
  notificationAudio,
  audiosQueue: { current: audiosQueueGlobal } as MutableRefObject<string[]>,
  isLoaded: false,
  load: () => {},
});

export const AudioProvider = ({ children }) => {
  const audiosQueue = useRef(audiosQueueGlobal);
  const [isLoaded, setIsLoaded] = useState(false);

  const load = () => {
    if (!isLoaded) {
      audio.load();
      notificationAudio.load();
      setIsLoaded(true);
    }
  };

  return (
    <AudioContext.Provider
      value={{
        audio,
        notificationAudio,
        audiosQueue,
        isLoaded,
        load,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => {
  return useContext(AudioContext);
};
