
export type MessageState = 'unsent' | 'sent' | 'delivered' | 'seen';

export function getHigherPriorityMessageState(state1: MessageState, state2: MessageState): MessageState {
  if (state1 === 'seen') {
    return state1;
  }
  if (state1 === 'delivered') {
    if (state2 === 'seen') {
      return state2;
    } else {
      return state1;
    }
  }
  if (state1 === 'sent') {
    if (state2 === 'seen' || state2 === 'delivered') {
      return state2;
    } else {
      return state1;
    }
  }
  if (state1 === 'unsent') {
    return state2
  }
  return 'unsent';
}