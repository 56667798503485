import React, { createContext, useRef } from "react";


export class SenderWrapper {
  private events: { name: string; args: any }[];
  private enabled: boolean;
  private inputRef: HTMLTextAreaElement | null;
  private fileInputRef: HTMLInputElement | null;
  private listeners: (() => void)[];

  constructor() {
    this.enabled = true;
    this.events = []
    this.inputRef = null;
    this.fileInputRef = null;
    this.listeners = [];
  }

  focus() {
    if (!this.enabled || !this.refAreSet()) {
      this.events.push({ name: 'focus', args: null });
      return;
    }
    this.inputRef.focus();
  }

  offInitEvents() {
    if (!this.refAreSet()) {
      this.events.push({ name: 'offInitEvents', args: null });
      return;
    }
    this.listeners.forEach((listener) => {
      this.inputRef.removeEventListener('focus', listener);
      this.inputRef.removeEventListener('click', listener);
    })
  }

  onInitEvents(callback) {
    if (!this.refAreSet()) {
      this.events.push({ name: 'onInitEvents', args: callback })
      return;
    }

    this.listeners.push(callback);
    this.inputRef.addEventListener('focus', callback);
    this.fileInputRef.addEventListener('click', callback);
  }



  disable() {
    if (!this.refAreSet()) {
      this.events.push({ name: 'disable', args: null });
      return;
    }
    this.enabled = false;
    this.inputRef.disabled = true;
    this.fileInputRef.disabled = true;
  }

  dispatchAllEvents() {
    this.events = this.events.filter((ev) => {
      if (!this.refAreSet()) {
        return true; // leave in events
      }
      switch (ev.name) {
        case 'enable':
          this.enable();
          return false;
        case 'disable':
          this.disable();
          return false;
        case 'offInitEvents':
          this.offInitEvents();
          return false;
        case 'onInitEvents':
          this.onInitEvents(ev.args);
          return false;
        case 'focus':
          if (!this.enabled) {
            return true; // leave in events
          } else {
            this.focus();
            return false;
          }
        default:
          return false;
      }
    });

  }

  refAreSet() {
    return !!this.inputRef && !!this.fileInputRef;
  }

  enable() {
    if (!this.refAreSet()) {
      this.events.push({ name: 'enable', args: null });
      return;
    }
    this.enabled = true;
    this.inputRef.disabled = false;
    this.fileInputRef.disabled = false;
    this.dispatchAllEvents();
  }

  setInputReference(inputRef) {
    this.inputRef = inputRef;
    this.dispatchAllEvents();
  }

  setFileInputRef(fileInputRef) {
    this.fileInputRef = fileInputRef;
    this.dispatchAllEvents();
  }

}

export const SenderContext = createContext<SenderWrapper | null>(null);

export function SenderProvider({ children }) {

  const senderRef = useRef(new SenderWrapper());

  return <SenderContext.Provider
    value={senderRef.current}
  >
    { children }
  </SenderContext.Provider>
}