import React, { useState } from "react";
import format from "date-fns/format";
import { Table as TableI } from "types";

import "./styles.scss";

type Props = {
  table: TableI;
  showTimeStamp: boolean;
};

function Table({ table, showTimeStamp }: Props) {
  const [rowNumber, setRowNumber] = useState(table.tableDef.rowstep || 4);

  const showMore = () => {
    const rowstep = table.tableDef.rowstep;
    if (table.data.length - (rowNumber + rowstep) < rowstep) {
      setRowNumber(table.data.length);
    } else {
      setRowNumber(rowNumber + rowstep);
    }
  };

  const showLess = () => {
    setRowNumber(table.tableDef.rowstep);
  };

  return (
    <div className={`rcw-table`}>
      <div className="rcw-table-wrapper">
        <p className="rcw-table-title">{table.tableDef.title}</p>
        <div className="rcw-table-content">
          <div className="rcw-table-row">
            {table.colDef.map((column, index) => (
              <div
                key={index}
                className={`rcw-table-item ${
                  table.tableDef.firstRowHeader ? "header" : ""
                }`}
              >
                {column.title}
              </div>
            ))}
          </div>
          {table.data.slice(0, rowNumber).map((row, outerIndex) => (
            <div key={outerIndex} className="rcw-table-row">
              {table.colDef.map((column, index) => (
                <div
                  key={outerIndex * 10 + index}
                  className={`rcw-table-item ${
                    table.tableDef.firstColHeader && index === 0 ? "header" : ""
                  } ${column.color || ""}`}
                >
                  {column.maxlength
                    ? row[index]?.text.substring(0, column.maxlength)
                    : row[index]?.text}
                </div>
              ))}
            </div>
          ))}
        </div>

        {rowNumber < table.data.length ? (
          <button
            className="rcw-message-action-button link"
            onClick={() => showMore()}
          >
            Show more
          </button>
        ) : (
          ""
        )}

        {rowNumber === table.data.length ? (
          <button
            className="rcw-message-action-button link"
            onClick={() => showLess()}
          >
            Show less
          </button>
        ) : (
          ""
        )}

        <p className="rcw-table-footer">{table.tableDef.footer}</p>
      </div>
      {showTimeStamp && (
        <span className="rcw-timestamp">
          {format(table.timestamp, "hh:mm")}
        </span>
      )}
    </div>
  );
}

export default Table;
