import { useState, useEffect, useRef } from "react";
import { uploadFile } from "./storage";
import mqtt from "paho-mqtt";
import MQTTPattern from "mqtt-pattern";
import log from "loglevel";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useSender } from "./useSender";
import { getHigherPriorityMessageState } from "./messageUtils";
import { ttlLocalStorage } from "../../utils/localStorage";
import { useInterval } from "./useInterval";


type Translation = {
  "et": string;
  "ru"?: string;
  "en"?: string;
}

type LabelTranslations = {
  [key: string]: Translation;
}

enum PlayMessageState {
  PLAY_NORMALLY = "play_normally",
  PLAY_AT_ONCE = "play_at_once",
  WAIT_TO_PLAY = "wait_to_play",
  PLAYED = "played",
}

const CWC_CONNECTION_USERNAME =
  window?.["app_env"].CWC_CONNECTION_USERNAME || `admin`;
const CWC_CONNECTION_PASSWORD =
  window?.["app_env"].CWC_CONNECTION_PASSWORD || `admin`;
const SHOW_STARTING_MESSAGES_NOTIFICATION =
  window?.["app_env"].REACT_APP_SHOW_STARTING_MESSAGES_NOTIFICATION || `true`;
const DISABLE_NOTIFICATION_SOUND =
  window?.["app_env"].REACT_APP_DISABLE_NOTIFICATION_SOUND === "true";

const MESSAGE_DELAY_SPEED =
  window?.["settings_env"]?.["MESSAGE_DELAY_SPEED"] || {};

const isPlaying = (audelem) => {
  return !audelem.paused;
};

const calculateMessageDelay = (message, messageSpeedSettings) => {
  if (!messageSpeedSettings) messageSpeedSettings = {};
  const mode = messageSpeedSettings.mode || "dynamic";
  let value = messageSpeedSettings.value || 220;
  if (mode == "constant") {
    if (!value) value = 0;
    return parseFloat(value) * 1000;
  }
  let wordCount = message.split(/\b\W+\b/).length;
  if (!value) value = 220;
  return (wordCount * 60000) / parseInt(value);
};

const NOTIFICATION_AUDIO_URL =
  "https://cwc.chatbot-prelive.titancs.mindtitan.com/notification.mp3";

const AUDIO_URL = window?.["app_env"].REACT_APP_BOT_API_FILES_URL;

class PageTitleNotification {
  originalTitle: string;
  interval: null | any;

  constructor() {
    this.originalTitle = document.title;
    this.interval = null;
  }
  On(notification, intervalSpeed = 1000) {
    if (this.interval) return;
    this.interval = setInterval(() => {
      document.title = this.originalTitle == document.title ? notification : this.originalTitle;
    }, intervalSpeed);
  }
  Off() {
    clearInterval(this.interval);
    this.interval = null;
    document.title = this.originalTitle;
  }
}

const pageTitleNotification = new PageTitleNotification();
const getJSON = (obj) => {
  let parsed;
  try {
    parsed = JSON.parse(obj);
  } catch (err) {
    parsed = false;
  }
  return parsed;
};

(window as any).PageTitleNotification = pageTitleNotification;

export const useMqttClient = ({
  url,
  user,
  customer,
  limit,
  reverse,
  chatWindowOpen,
  defaultLabelLanguage,
  cachingEnabled,
}) => {
  const [customerData, _setCustomerData] = useState({
    ...customer,
  });
  const customerDataRef = useRef(customerData);
  const parseCustomer = () => {
    return JSON.parse(ttlLocalStorage.getItem("customer") || "null");
  };
  const sender = useSender();
  const defaultMessages =
    window?.["settings_env"]?.["CWC_MESSAGES"]?.[defaultLabelLanguage] || [];
  const STARTING_MESSAGES = (defaultMessages.length > 0
    ? defaultMessages
    : window?.["settings_env"]?.["CWC_MESSAGES"]?.["DEFAULT"] || []
  ).map((msg, index) => ({
    isCwcMessage: true,
    message_id: `cwc_message_${index}`,
    role: "appMaker",
    type: "text",
    text: msg["message"],
    delay: msg["delay"],
    metadata: {},
    actions: [],
    message_helpdesk_state: "seen",
    message_client_state:
      SHOW_STARTING_MESSAGES_NOTIFICATION === "true" ? "delivered" : "seen",
    isStartingMessage: true,
  }));
  if (STARTING_MESSAGES.length > 0)
    STARTING_MESSAGES[STARTING_MESSAGES.length - 1].isLastCwcMessage = true;
  const TRIGGER = window?.['settings_env']?.['CWC_FLOW_TRIGGER'];
  const INIT_SESSION_ON_START = window?.['settings_env']?.['INIT_SESSION_ON_START'];

  const setCustomerData = (data) => {
    customerDataRef.current = data;
    const currentCustomer = parseCustomer();
    if (currentCustomer) {
      ttlLocalStorage.setItem(
        "customer",
        JSON.stringify({
          ...data,
          dialogIsOpen: !!currentCustomer.dialogIsOpen,
          dialogWasEverOpen: !!currentCustomer.dialogWasEverOpen,
          startingMessagedPlayed: !!currentCustomer.startingMessagedPlayed,
        })
      );
    }

    _setCustomerData(data);
  };


  const getPlayMessageInitialState = (): PlayMessageState => {
    if (cachingEnabled) {
      const customer = parseCustomer();
      if (customer?.chat_session_uid) {
        return PlayMessageState.WAIT_TO_PLAY;
      } else if (customer?.startingMessagedPlayed) {
        return PlayMessageState.PLAY_AT_ONCE;
      }
    }
    return PlayMessageState.PLAY_NORMALLY;
  };

  const [clientId, setClientId] = useState(
    "mqttjs_" + Math.random().toString(16).substr(2, 8)
  );
  const [client, setClient] = useState(null);
  const [status, _setStatus] = useState("offline");
  const [firstMessageSent, setFirstMessageSent] = useState(false);
  const [connectionEstabished, setConnectionEstabished] = useState(false);
  const [token, setToken] = useState(null);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);
  const [qos, setQos] = useState(undefined);
  const [currentStartingMessage, setCurrentStartingMessage] = useState(0);
  const [messages, setMessages] = useState([]);
  const [pendingMessages, setPendingMessages] = useState([]);
  const [pendingSolution, setPendingSolution] = useState(null);
  const [
    pendingCartDevicesInsuranceData,
    setPendingCartDevicesInsuranceData,
  ] = useState(null);
  const [pendingCartLockedData, setPendingCartLocked] = useState(null);
  const [topic, setTopic] = useState(null);
  const [playMessageState, setPlayMessageState] = useState<PlayMessageState>(
    getPlayMessageInitialState()
  );
  const [labelTranslations, setLabelTranslations] = useState<LabelTranslations | null>(null);
  const [confirmSolutionData, setConfirmSolutionData] = useState(undefined);
  const [hasWelcomeMessage, setHasWelcomeMessage] = useState(false);

  const messagesRef = useRef(messages);
  const clientRef = useRef(client);
  const topicRef = useRef(topic);
  const tokenRef = useRef(token);
  const readyRef = useRef(ready)
  const options = { qos: 2 };
  const audioRef = useRef(null);
  const notificationAudioRef = useRef(null);
  const audioQueue = useRef<string[] | null>(null);
  // when user chooses segment from bubble skip asking for diagram topic/label confirmation
  const skipConfirm = useRef(false);

  limit = limit || 100;

  const playAudioMessage = (audioFileName: string, ignoreUrl = false) => {
    if (!audioRef.current) return;
    let audioUrl = `${AUDIO_URL}/${audioFileName}`;
    if (audioFileName.includes('http://') || audioFileName.includes('https://')) {
      audioUrl = audioFileName;
    }
    if (ignoreUrl) {
      audioUrl = audioFileName;
    }
    audioQueue.current?.push(audioUrl);
    if (!isPlaying(audioRef.current)) {
      audioRef.current.src = audioUrl;
      audioRef.current.play();
    }
  };
  const playNotificationAudio = () => {
    if (DISABLE_NOTIFICATION_SOUND) return;
    if (!notificationAudioRef.current) return;
    notificationAudioRef.current.play();
  };
  const sendMessage = (topic, payload, qos = 1) => {
    try {
      const msg = new mqtt.Message(JSON.stringify(payload))
      msg.retained = true
      msg.destinationName = topic
      msg.qos = qos
      clientRef.current.send(msg);
    }
    catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (
      playMessageState === PlayMessageState.PLAYED ||
      playMessageState === PlayMessageState.WAIT_TO_PLAY
    )
      return;
    if (playMessageState === PlayMessageState.PLAY_NORMALLY) {
      const message = STARTING_MESSAGES[currentStartingMessage];
      if (!message) return;
      let timer = setTimeout(
        () => {
          setMessages((old) => [...old, message]);
          if (currentStartingMessage + 1 < STARTING_MESSAGES.length) {
            setCurrentStartingMessage(currentStartingMessage + 1);
          }
          if (message?.isLastCwcMessage) {
            setPlayMessageState(PlayMessageState.PLAYED);
            if (cachingEnabled) {
              ttlLocalStorage.setItem(
                "customer",
                JSON.stringify({
                  ...parseCustomer(),
                  startingMessagedPlayed: true,
                })
              );
            }
            sender.enable();
          }
        },
        message.delay !== null
          ? message.delay * 1000
          : calculateMessageDelay(message.text, MESSAGE_DELAY_SPEED)
      );

      return () => {
        clearTimeout(timer);
      };
    } else if (playMessageState === PlayMessageState.PLAY_AT_ONCE) {
      setMessages(
        STARTING_MESSAGES.map(msgs => ({
          ...msgs,
          message_client_state:
            SHOW_STARTING_MESSAGES_NOTIFICATION === "true" &&
              !parseCustomer()?.dialogWasEverOpen
              ? "delivered"
              : "seen",
          ignoreNotification: true,
        })).concat(messages)
      );
      setPlayMessageState(PlayMessageState.PLAYED);
    }
  }, [currentStartingMessage, playMessageState]);

  function setAudioContext(audio, audioQueueRef, notificationAudio) {
    audioRef.current = audio;
    audioQueue.current = audioQueueRef.current;
    notificationAudio.src = NOTIFICATION_AUDIO_URL;
    notificationAudioRef.current = notificationAudio;
  }

  function setStatus(newStatus) {
    log.info("STATUS UPDATE:", newStatus);
    _setStatus(newStatus);
  }

  const notifySeenMessages = () => {
    if (document.hidden || !chatWindowOpen.current) return;

    let deliveredMessages = messagesRef.current.filter(
      (m) =>
        (!m.message_client_state || m.message_client_state === "delivered") &&
        m.role === "appMaker" &&
        m.type !== "flow_ended"
    );
    if (deliveredMessages.length > 0) {
      const canSendUpdate = topicRef.current && clientRef.current;
      let messagesTemp = JSON.parse(JSON.stringify(messagesRef.current));
      let canSetMessages = false;
      messagesTemp?.forEach((message) => {
        if (
          deliveredMessages.find((m) => m.message_id === message.message_id) &&
          (canSendUpdate || message.isCwcMessage)
        ) {
          canSetMessages = true;
          message.message_client_state = "seen";
        }
      });
      if (canSetMessages) setMessages(messagesTemp);
      if (canSendUpdate) {
        sendMessage(`${topicRef.current}/message_client_state/${tokenRef.current}`, {
          message_ids: deliveredMessages
            .filter((m) => !m.isCwcMessage)
            .map((m) => m.message_id),
          state: "seen",
          role: "appUser",
        })

        pageTitleNotification.Off();
      }
    }
  };


  useEffect(() => {
    if (
      STARTING_MESSAGES.length > 0 &&
      !(
        cachingEnabled &&
        ttlLocalStorage.getItem("customer")
      )
    ) {
      sender.disable();
    }
    if (cachingEnabled && parseCustomer()?.chat_session_uid) {
      setFirstMessageSent(true);
    } else {
      sender.onInitEvents(() => {
        sender.offInitEvents();
        setFirstMessageSent(true);
      });
      if (INIT_SESSION_ON_START) {
        sender.focus();
      }
      if (TRIGGER) {
        setPendingSolution(TRIGGER);
        sender.focus();
      }
    }
    document.addEventListener(
      "visibilitychange",
      function () {
        notifySeenMessages();
      },
      false
    );
  }, []);


  const onSubscribed = (err, grant, messageJSON) => {
    grant = grant?.grantedQos || [];
    if (!err && !grant[0]) return;
    const qos = grant[0];
    const granted = qos <= 10;

    setQos(qos);
    setReady(!err && granted);
    setError(!!err || !granted);
    if (err) {
      console.error(`Error subscribing to topic "${topic}"`, err);
      return;
    }
    if (!granted) {
      console.error(
        `Error subscribing to topic "${topic}". Permission denied.`,
        qos,
        grant,
        err
      );
      return;
    }
    const { has_welcome_message, uid, token } = messageJSON;
    setHasWelcomeMessage(has_welcome_message);
    if (has_welcome_message) {
      sendMessage(`user/${uid}/chat/greeting/${token}`, { uid, role: "appUser" })
    }
  };
  const onNewMessage = (message) => {
    message.received = new Date().getTime();
    if (message.type === 'history_length') {
      if (message.history_length !== messagesRef.current.length - (STARTING_MESSAGES?.length ?? 0)) {
        console.log(`messages in history: ${message.history_length}; messages in state: ${messagesRef.current.length};
        starting messages: ${STARTING_MESSAGES?.length}, fetching history`);
        sendMessage(`${topic}/fetch_history/${token}`, {
          role: "appUser",
          chat_session_uid: topic.split("/")[1]
        })
      }
      return;
    }
    if (message.type === 'history') {
      setMessages((old) => {
        if (message.history.length !== old.length - (STARTING_MESSAGES?.length ?? 0)) {
          return [...old.filter(m => m.isStartingMessage), ...message.history];
        }
        return old;
      });
      return;
    }
    if (message.type === 'label_translations') {
      setLabelTranslations(message.translations);
      return;
    }
    if (message.type === 'new_chat_session') {
      const newHash = message.new_chat_session.hash;
      setCustomerData({
        ...customerDataRef.current,
        hash: newHash,
      });
      return;
    }
    if (
      message.type !== "message_status_changed" &&
      message.role !== "appUser" &&
      message.type !== "flow_ended"
    ) {
      playNotificationAudio();
    }
    if (message.audio_path) {
      playAudioMessage(message.audio_path);
    }
    setMessages((old) => {
      if (message.type === "message_status_changed") {
        return old.map((m) => {
          m.message_helpdesk_state = getHigherPriorityMessageState(
            m.message_helpdesk_state, message.state
          );
          return m;
        })
      }
      if (old.length >= limit) {
        if (reverse) {
          old = old.slice(0, limit - 1);
        } else {
          old = old.slice(1);
        }
      }
      let elemExistsIndex = old.findIndex((msg) => {
        if (msg.message_uid && msg.message_uid === message.message_uid) {
          return true;
        } else if (msg.message_id && msg.message_id === message.message_id) {
          return true;
        }

        return false;
      });
      if (elemExistsIndex === -1 && message.role === "appUser") {
        return old;
      }
      if (elemExistsIndex !== -1) {
        let oldTemp = [...old];
        if (message.type === 'file' && message.actions.length === 0) {
          message.actions = oldTemp[elemExistsIndex].actions;
        }
        oldTemp[elemExistsIndex] = message;
        return oldTemp;
      } else {
        if (reverse) {
          return [message, ...old];
        } else {
          return [...old, message];
        }
      }
    });
  };

  useEffect(() => {
    if (!firstMessageSent || connectionEstabished) return;
    if (!user) return;
    setConnectionEstabished(true);
    const connection = new mqtt.Client(url, clientId);
    const connectOptions = {
      onSuccess: () => {
        console.log('Connected to MQTT broker');
        const topic = `client/${clientId}`;
        clientRef.current = connection

        connection.subscribe(topic, {
          qos: 2,
        });
        if (topicRef.current && tokenRef.current) {
          connection.subscribe(`${topicRef.current}/${tokenRef.current}`, {
            qos: 2,
            onSuccess: () => {
              setReady(true)
            }
          })
        };
        sendMessage(`client/${clientId}`, {
          ...customerDataRef.current,
          default_lang: defaultLabelLanguage,
          topic: topicRef.current,
        }, 2)
      },
      onFailure: (errorMessage) => {
        console.log('Failed to connect to MQTT broker:', errorMessage);
      },
      // cleanSession: true,
      userName: CWC_CONNECTION_USERNAME,
      password: CWC_CONNECTION_PASSWORD,
      keepAliveInterval: 3,
      reconnect: true
    };
    connection.onConnectionLost = (err) => {
      setReady(false);
      console.log('connection lost', err)
    }
    connection.connect(connectOptions);
    const onMessage = (params) => {
      const _topic = params.destinationName
      const message = params.payloadString
      let messageJSON = getJSON(message);
      if (_topic.startsWith("client") && messageJSON) {
        if (messageJSON.token) setToken(messageJSON.token);
        if (messageJSON.history) {
          // setReady(true);
          setMessages((current) => [
            ...current.filter((msg) => msg.isStartingMessage),
            ...messageJSON.history.map((msg) => ({
              ...msg,
            })),
          ]);
        }
        if (messageJSON.uid) {
          const existingCustomer = parseCustomer();
          if (existingCustomer) {
            if (playMessageState === PlayMessageState.WAIT_TO_PLAY) {
              setPlayMessageState(
                messageJSON.new_session_created
                  ? PlayMessageState.PLAY_NORMALLY
                  : PlayMessageState.PLAY_AT_ONCE
              );
              if (messageJSON.new_session_created) {
                ttlLocalStorage.setItem(
                  "customer",
                  JSON.stringify({
                    ...existingCustomer,
                    dialogWasEverOpen: undefined,
                    startingMessagedPlayed: undefined,
                  })
                );
              }
            }
            if (
              !existingCustomer.chat_session_uid ||
              existingCustomer.chat_session_uid === messageJSON.uid
            ) {
              customerDataRef.current.default_dialogue_started =
                existingCustomer.default_dialogue_started;
            }
            setCustomerData({
              ...customerDataRef.current,
              chat_session_uid: messageJSON.uid,
            });
          }
          setTopic(`user/${messageJSON["uid"]}/chat`);

          connection.unsubscribe(_topic);

          if (messageJSON.token) {
            setReady(true);
            connection.subscribe(
              `user/${messageJSON["uid"]}/chat/${messageJSON.token}`,
              {
                ...options, onSuccess: (qos) => {
                  onSubscribed(false, qos, messageJSON)
                },
                onFailure: (err) => {
                  onSubscribed(err, undefined, messageJSON)
                }
              }
            );
          }

        }
      } else if (topicRef.current) {
        if (!MQTTPattern.matches(topicRef.current, _topic)) return;
        if (messageJSON) onNewMessage(messageJSON);
      }
    };
    connection.onMessageArrived = onMessage;
    if (client) client.disconnect();

    connection.onReconnect = () => {
      setStatus('reconnecting');
      console.log('Reconnecting...');
    };

    connection.onOffline = () => {
      setStatus('offline');
      console.log('Client is offline');
    };

    connection.onError = (error) => {
      setStatus('error');
      console.error('Error:', error);
    };
    setClient(connection);

    return () => {
      log.info("Effect restart", url, user.user_id);
      if (client) client.disconnect();
      setClient(null);
    };
  }, [firstMessageSent]);

  const clearMessages = () => {
    setMessages([]);
  };

  const notifyDeliveredMessages = () => {
    let sentMessageIds = messagesRef.current
      .filter(
        (m) =>
          (!m.message_client_state || m.message_client_state === "sent") &&
          m.role === "appMaker" &&
          m.type !== "flow_ended"
      )
      .map((m) => m.message_id);
    if (sentMessageIds.length > 0 && topicRef.current) {
      let messagesTemp = JSON.parse(JSON.stringify(messagesRef.current));
      messagesTemp?.forEach((message) => {
        if (sentMessageIds.includes(message.message_id))
          message.message_client_state =
            document.hidden || !chatWindowOpen.current ? "delivered" : "seen";
      });
      setMessages(messagesTemp);
      sendMessage(`${topicRef.current}/message_client_state/${tokenRef.current}`,
        {
          message_ids: sentMessageIds,
          state:
            document.hidden || !chatWindowOpen.current ? "delivered" : "seen",
          role: "appUser",
        })
      if (document.hidden) {
        let numMessagesNotification = messagesTemp.filter(
          (m) => m.message_client_state === "delivered"
        ).length;
        pageTitleNotification.Off();
        pageTitleNotification.On(
          `${numMessagesNotification} new message${numMessagesNotification > 1 ? "s" : ""
          }!`
        );
      }
    }
  };

  useEffect(() => {
    messagesRef.current = messages;
    topicRef.current = topic;
    tokenRef.current = token;
    clientRef.current = client;
    notifyDeliveredMessages();
    notifySeenMessages();
  }, [messages.length, topic, token]);



  useEffect(() => {
    if (!client) return;
    return () => {
      if (client) {
        if (topic) client.unsubscribe(topic);
      }

      // setReady(false);
      setError(false);
    };
  }, [client, topic, JSON.stringify(options)]);

  const cartLocked = (cartData) => {
    if (!ready) {
      setPendingCartLocked(cartData);
      return;
    }

    sendMessage(`${topic}/cart_locked/${token}`, { ...cartData, role: "appUser" })
  };

  const skipAskingForConfirm = () => {
    skipConfirm.current = true;
  }

  const resetSkipConfirm = () => {
    skipConfirm.current = false;
  }

  const clearConfirmSolutionData = () => {
    setLabelTranslations(null);
    setConfirmSolutionData(null);
  }

  const publishStartSolution = (solutionData) => {
    sendMessage(`${topic}/start_solution/${token}`, {
      dialogue_code: solutionData.dialogCode,
      context_data: solutionData.contextData,
      force: solutionData.force,
      trigger_type: solutionData.triggerType,
      role: "appUser",
      uid: customerDataRef.current.chat_session_uid,
      defaultLabelLanguage: defaultLabelLanguage
    })
    if (confirmSolutionData) {
      clearConfirmSolutionData();
    }
  }

  const startSolution = (solutionData) => {
    if (!ready) {
      setPendingSolution(solutionData);
      return;
    }
    if (solutionData.confirmLabel &&
      (solutionData.triggerType.toLowerCase() === 'cta' || solutionData.triggerType.toLowerCase() === 'url') &&
      !skipConfirm.current) {
      if (!labelTranslations?.[solutionData.dialogCode]) {
        sendMessage(`${topic}/translate_label/${token}`, {
          label: solutionData.dialogCode,
          role: "appUser",
          chat_session_uid: topic.split("/")[1]
        })
      }
      setConfirmSolutionData(solutionData);
      return;
    }
    resetSkipConfirm();
    publishStartSolution(solutionData)
  };

  const cartDevicesInsurance = (cartData) => {
    if (!ready) {
      setPendingCartDevicesInsuranceData(cartData);
      return;
    }
    sendMessage(`${topic}/cart_devices_insurance/${token}`, { ...cartData, role: "appUser" })
  };

  const publish = (message, _options?) => {
    if (!readyRef.current) {
      setPendingMessages((old) => {
        old.push(message);
        return old;
      });
      return;
    }
    else setPendingMessages([])
    let messageUid = uuidv4();
    setMessages((current) => [
      ...current,
      {
        ...message,
        message_helpdesk_state: "unsent",
        message_uid: messageUid,
        message_client_state: "seen",
      },
    ]);
    sendMessage(`${topic}/${token}`, {
      ...message,
      message_uid: messageUid,
      message_helpdesk_state: "sent",
      message_client_state: "seen",
    }, 2)
  };

  const sendFile = (message, _options?) => {
    if (!readyRef.current) {
      setPendingMessages((old) => {
        old.push(message);
        return old;
      });
      return;
    }
    let messageUid = uuidv4();
    setMessages((current) => [
      ...current,
      {
        ...message,
        message_helpdesk_state: "unsent",
        message_uid: messageUid,
        message_client_state: "seen",
        text: message.file.name,
      },
    ]);
    let data = new FormData();
    data.append("file", message.file);
    data.append("chat_session_uid", message.authorId);
    data.append("message_uid", messageUid);
    uploadFile(message.file)
      .then((filename) => {
        sendMessage(`${topic}/${token}`, {
          ...message,
          message_uid: messageUid,
          message_helpdesk_state: "sent",
          message_client_state: "seen",
          text: message.file.name,
          file: filename,
          metadata: {
            content_type: "file",
          },
        }, 2)
      })
      .catch((err) => {
        setMessages((old) =>
          old.map((m) =>
            m.message_uid === messageUid
              ? { ...m, message_helpdesk_state: "failed" }
              : m
          )
        );
        console.log(err);
      });
  };

  useEffect(() => {
    readyRef.current = ready
    if (ready) {
      pendingMessages.forEach((message) =>
        message.type === "file" ? sendFile(message) : publish(message)
      );
      if (pendingSolution) {
        startSolution(pendingSolution);
        setPendingSolution(null);
      }
      if (pendingCartDevicesInsuranceData) {
        cartDevicesInsurance(pendingCartDevicesInsuranceData);
        setPendingCartDevicesInsuranceData(null);
      }
      if (pendingCartLockedData) {
        cartLocked(pendingCartLockedData);
        setPendingCartLocked(null);
      }
    }
  }, [ready]);

  const changeSession = (new_session_data) => {
    const { customer, externalToken } = new_session_data;
    log.info("---- CHANGE SESSION ----");
    const newSessionData = {
      ...customerDataRef.current,
      ..._.mapValues(customer, (o) => o.toString()),
      default_lang: defaultLabelLanguage,
      chatSessionUid: topicRef.current.split("/")[1],
      externalToken,
    };
    setCustomerData(newSessionData);
    sendMessage(`${topic}/change_chat_session/${token}`, { ...newSessionData, role: "appUser" }, 2)
  };

  const resetSession = () => {
    log.info("---- RESET SESSION ----");
    sendMessage(`${topic}/close_session/${token}`, {
      ...customerDataRef.current,
      chat_session_uid: topic.split("/")[1],
      role: "appUser",
    })
    window.setTimeout(window.location.reload.bind(window.location), 100);
  };

  useInterval(() => {
    if (!ready || !chatWindowOpen || document.hidden) {
      return;
    }

    sendMessage(`${topic}/history_length/${token}`, {
      chat_session_uid: topic.split("/")[1],
      role: "appUser",
    })
  }, 30_000)

  return {
    mqtt,
    status,
    ready,
    error,
    messages,
    qos,
    publish,
    sendFile,
    clearMessages,
    changeSession,
    resetSession,
    customerData,
    notifySeenMessages,
    setAudioContext,
    startSolution,
    segment: customerData.segment,
    setSegment: (segment) =>
      setCustomerData({
        ...customerData,
        segment,
      }),
    cartDevicesInsurance,
    cartLocked,
    user: {
      user_id: topic?.split("/")?.[1],
      token,
    },
    labelTranslations,
    confirmSolutionData,
    clearConfirmSolutionData,
    publishStartSolution,
    skipAskingForConfirm,
    hasWelcomeMessage
  };
};
