import { useState } from "react";
import { ttlLocalStorage } from "../../utils/localStorage";

export function useCustomer(): [() => any, (arg0: any) => void] {
  function parseCustomer() {
    return JSON.parse(ttlLocalStorage.getItem("customer") || "{}");
  }
  let [_, setInnerCustomer] = useState(parseCustomer);


  function setCustomer(customer) {
    ttlLocalStorage.setItem(
      "customer",
      JSON.stringify(customer)
    );
    setInnerCustomer(customer);
  }

  return [parseCustomer, setCustomer]
}