import Plugin from "markdown-it-regexp";

/**
 * Adds support for font-awesome icons (v5).
 *
 * Syntax
 * :{style}-{name}[#{hexColor} | .{className}]:
 *
 * Styles
 * fas - solid
 * far - regular
 * fab - brands (not loaded)
 *
 * Examples
 *
 * :fas-flag: => renders solid flag icon
 * :fas-flag#ffd503: => renders solid flag with color: #ffd503
 * :fas-flag.success: => renders solid flag with class 'success'
 *
 * Find available (free) icons here: https://fontawesome.com/icons?d=gallery&p=2&s=regular,solid&m=free
 * Copy the icon style (fas, far) and icon name
 */
export default function fontawesome_plugin(md: any) {
  md.use(
    Plugin(/:fa([\w])-([\w-]+)([.#][\w\d]+)?:/, function (
      match: string[],
      utils: any
    ) {
      const fontAwesomeStyleEsc: string = "fa" + utils.escape(match[1]);
      const fontAwesomeIconEsc: string = "fa-" + utils.escape(match[2]);
      const colorMatchEsc: string | undefined =
        match?.[3] && utils.escape(match[3]);

      const styleList = [];
      const classList = [fontAwesomeStyleEsc, fontAwesomeIconEsc];
      if (colorMatchEsc) {
        if (colorMatchEsc[0] === "#") {
          styleList.push(["color: " + colorMatchEsc]);
        } else if (colorMatchEsc[0] === ".") {
          classList.push(colorMatchEsc.substr(1));
        }
      }

      const classes = classList.length && 'class="' + classList.join(" ") + '"';
      const styles = styleList.length && 'style="' + styleList.join("; ") + '"';

      return ["<i", classes, styles, "></i>"].filter((el) => el).join(" ");
    })
  );
}
