export function canAccessLocalStorage() {
  try {
    return !!localStorage && typeof window !== undefined;
  } catch (e) {
    return false;
  }
}

class SafeLocalStorage {
  getItem(key: string) {
    if (canAccessLocalStorage()) {
      return localStorage.getItem(key);
    }
  }

  setItem(key: string, value: string) {
    if (canAccessLocalStorage()) {
      return localStorage.setItem(key, value);
    }
  }

  clear() {
    if (canAccessLocalStorage()) {
      localStorage.clear();
    }
  }
}

export const safeLocalStorage = new SafeLocalStorage();

class TtlLocalStorage {
  ttlSeconds: number;


  constructor(ttlSeconds: number) {
    this.ttlSeconds = ttlSeconds;
  }

  setTtl() {
    safeLocalStorage.setItem('ttl', JSON.stringify((new Date()).getTime() / 1000))
  }

  checkTtl() {
    const ttl = JSON.parse(safeLocalStorage.getItem('ttl') || '0');
    if (ttl + this.ttlSeconds < ((new Date()).getTime() / 1000)) {
      safeLocalStorage.clear();
    }
  }

  getItem(key: string): string | null {
    this.checkTtl();
    return safeLocalStorage.getItem(key);
  }

  setItem(key: string, value: string) {
    this.setTtl();
    safeLocalStorage.setItem(key, value);
  }

  clear() {
    safeLocalStorage.clear();
  }
}

export const ttlLocalStorage = new TtlLocalStorage(JSON.parse(process.env.ttlSeconds || '43200'));
// 12h by default
