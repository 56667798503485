import React, { useEffect } from "react";

import { useQuery } from "cwc-provider";
export default function App() {
  const search = useQuery();
  const appendSdkScript = () => {
    const windowRef = window as any;
    const script = document.createElement("script");
    script.src = "/chat-web-sdk-v2.js";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    script.onload = () => {
      windowRef.initChatSdk(
        `${windowRef.location.protocol}//${windowRef.location.host}/mindtitan`,
        {
          options: {
            exclude_launch: true,
            title: "MindTitan",
            languages: ["en"],
            timezone: "Europe/Helsinki",
            other_styles: {
              ".rcw-widget-container": `{
                width: 100%;
                max-width: 100%;
              }`,
            },
            custom_bubble_style: {
              "z-index": 1000000000,
              border: "none",
              height: "100%",
              position: "fixed",
              width: "100%",
              "box-shadow": "0 2px 10px 1px #b5b5b5",
              opacity: 1,
              transition: "bottom 0.6s, opacity 0.8s",
            },
            custom_bubble_style_mobile: {
              bottom: "0%",
              height: "100%",
              border: "none",
              position: "fixed",
              width: "100%",
              right: "0px",
              transition: "bottom 0.6s, opacity 0.8s",
            },
          },
          customer: {
            customerFullName: search.get("customerFullName") || "",
            message: search.get("message") || "",
          },
        }
      );
    };
    document.body.append(script);
  };
  useEffect(() => {
    appendSdkScript();
  }, []);
  return <html></html>;
}
