import React from "react";

import './style.scss';

type CommonProps = {
  currentLanguage: 'et' | 'ru';
}

type Props = {
  helloMessage?: string;
  firstChoice?: string;
  secondChoice?: string;
  message?: string;
  onFirstChoice: () => void;
  onSecondChoice: () => void;
}

type ConfirmSolutionProps = CommonProps & {
  clearConfirmSolutionLabel: () => void;
  startSolution: () => void;
  label: string;
}

type SegmentProps = CommonProps & {
  setSegment: (segment: 'private' | 'organization') => void;
}

const helloMessageMap = {
  ru: 'Здравствуйте, я Анника',
  et: 'Tere, olen Annika.'
}

const messageMap = {
  ru: "Пожалуйста уточните, Вы являетесь частным- или бизнес клиентом?",
  et: 'Palun täpsusta, kas oled era- või äriklient.',
}

const privateClientMap = {
  et: 'Eraklient',
  ru: 'Частный'
}

const bussynessClientMap = {
  et: 'Äriklient',
  ru: 'Бизнес'
}

function Overlay({helloMessage, message, firstChoice, secondChoice, onFirstChoice, onSecondChoice}: Props) {


  return (
    <div className="overlay">
      <div className="overlay-top">
        <div className="overlay-img">
          <img src="/elisa/chat-bubble-big.png" alt=""/>
        </div>
        <div className="overlay-text">
          {helloMessage && <p className="overlay-text-content">{helloMessage}</p>}
          <div className="overlay-text-box">
            <p className="overlay-text-content">{message}</p>
          </div>
        </div>
      </div>
      <div className="overlay-bottom">
        <div className="overlay-button-content">
          <ul className="overlay-button-ul">
            <li className="overlay-button-li">
              <a
                className="overlay-button"
                onClick={() => onFirstChoice()}
              >
                {firstChoice}
              </a>
            </li>
            <li className="overlay-button-li">
              <a
                className="overlay-button"
                onClick={() => onSecondChoice()}
              >
                {secondChoice}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export function SegmentOverlay({currentLanguage, setSegment}: SegmentProps) {
  const language = currentLanguage || "et";
  return <Overlay helloMessage={helloMessageMap[language]}
                  message={messageMap[language]}
                  firstChoice={privateClientMap[language]}
                  secondChoice={bussynessClientMap[language]}
                  onFirstChoice={() => setSegment('private')}
                  onSecondChoice={() => setSegment('organization')}
  />

}


const yesNo = {
  et: {
    yes: 'Jah',
    no: 'Ei'
  },
  ru: {
    yes: 'Да',
    no: 'Нет'
  }
}

export function ConfirmSolutionOverlay({currentLanguage, label, startSolution, clearConfirmSolutionLabel}: ConfirmSolutionProps) {
  const language = currentLanguage || "et";
  let message;
  if (language === "ru") {
    message = `Хотите начать беседу на тему ${label}?`;
  } else {
    message = `Kas soovite alustada vestlust ${label} teemal?`;
  }

  return <Overlay onFirstChoice={startSolution} onSecondChoice={clearConfirmSolutionLabel}
                  message={message} firstChoice={yesNo[language]["yes"]} secondChoice={yesNo[language]["no"]}/>
}

