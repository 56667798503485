import React from "react";

export const constructActions = (
  actions,
  sendMessage,
  setLocation: (uri: string) => void
) => {
  return (
    <>
      {actions.map((action, index) => {
        switch (action.type) {
          case "same-page-link":
            return (
              <a
                key={index}
                rel="noopener noreferrer"
                className={`rcw-message-action-button ${
                  actions.length > 2 ? "full-width " : ""
                }link`}
                onClick={() => {
                  setLocation(action.uri);
                }}
              >
                {action.text}
              </a>
            );
          case "link":
            // code block
            return (
              <a
                key={index}
                rel="noopener noreferrer"
                className={`rcw-message-action-button ${
                  actions.length > 2 ? "full-width " : ""
                }link`}
                href={`${action.uri}`}
                target="_blank"
              >
                {action.text}
              </a>
            );
          case "reply":
            // code block
            return (
              <button
                key={index}
                className={`rcw-message-action-button ${
                  actions.length > 2 ? "full-width" : ""
                }`}
                style={actions.length > 2 ? { width: "100% !important" } : {}}
                onClick={(event) => {
                  sendMessage({
                    target: {
                      message: {
                        value: action.payload,
                        inputNodeId: action.input_node_id,
                      },
                    },
                    preventDefault: () => {},
                    currentTarget: event.currentTarget,
                  });
                }}
              >
                {action.text.split("-mt_internal_separator-").length == 2
                  ? action.text.split("-mt_internal_separator-")[1]
                  : action.text}
              </button>
            );
          case "postback":
            return (
              <button
                key={index}
                className={`rcw-message-action-button ${
                  actions.length > 2 ? "full-width" : ""
                }`}
                style={actions.length > 2 ? { width: "100% important" } : {}}
                onClick={() => {
                  sendMessage({
                    target: {
                      message: {
                        value: action.payload,
                        payload: action.payload,
                        type: "postback",
                      },
                    },
                    preventDefault: () => {},
                  });
                }}
              >
                {action.text.split("-mt_internal_separator-").length == 2
                  ? action.text.split("-mt_internal_separator-")[1]
                  : action.text}
              </button>
            );
          default:
          // code block
        }
        return null;
      })}
    </>
  );
};

export const parseMessageLinks = (message) => {
  const text = message?.text || "";
  if (text.includes("%%[")) {
    const start = text.indexOf("%%[");
    const middle = text.indexOf("](", start + 1);
    const end = text.indexOf(")", middle + 1);

    const parsedText =
      text.substring(0, start) + text.substring(end, text.length - 1);
    const title = text.substring(start + 3, middle);
    const link = text.substring(middle + 2, end);

    return {
      parsedActions: [
        {
          type: "same-page-link",
          text: title,
          uri: link,
        },
      ],
      outputText: parsedText,
    };
  } else if (text.includes("%[")) {
    const start = text.indexOf("%[");
    const middle = text.indexOf("](", start + 1);
    const end = text.indexOf(")", middle + 1);

    const parsedText =
      text.substring(0, start) + text.substring(end, text.length - 1);
    const title = text.substring(start + 2, middle);
    const link = text.substring(middle + 2, end);

    return {
      parsedActions: [
        {
          type: "link",
          text: title,
          uri: link,
        },
      ],
      outputText: parsedText,
    };
  } else if (text.includes("-mt_internal_separator-")) {
    return {
      parsedActions: [],
      outputText: text.split("-mt_internal_separator-")[1],
    };
  } else {
    return { parsedActions: [], outputText: text };
  }
};
