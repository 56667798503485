import axios from "axios";
import { CwcProvider, useQuery } from "cwc-provider";
import log from "loglevel";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import ChatWidget from "./widget";
import { ttlLocalStorage } from "../../utils/localStorage";
import { useSettings } from "../../utils/SettingsProvider";
import "antd/dist/antd.css";

const decideWhichSegment = (given, cached, defaultSegment) => {
  if (!cached && !given) {
    return defaultSegment;
  }
  if (cached && given === "unknown") {
    return cached;
  }
  return given;
};

const initializeCustomer = (search, options) => {
  const cachedCustomer =
    options.cachingEnabled &&
    JSON.parse(ttlLocalStorage.getItem("customer") || 'null');
  const parseCustomerGroups = () => {
    try {
      return (
        search.get("customerGroups") || cachedCustomer?.["customerGroups"]
      ).split(",");
    } catch (err) {
      return [];
    }
  };
  const newUserUUID = uuidv4();
  return {
    externalToken: search.get("externalToken"),
    customerId:
      search.get("customerId") || cachedCustomer?.["customerId"] || "0",
    code:
      search.get("code") || cachedCustomer?.["code"] || newUserUUID.toString(),
    secure: search.get("secure") || cachedCustomer?.["secure"] || "false",
    segment: decideWhichSegment(
      search.get("segment"),
      cachedCustomer?.["segment"],
      "Anonymous"
    ),
    customer_proxy:
      search.get("customer_proxy") || cachedCustomer?.["customer_proxy"] || "",
    customerFullName:
      search.get("customerFullName") ||
      cachedCustomer?.["customerFullName"] ||
      "client ".concat(newUserUUID.toString().split("-")[0]),
    message: search.get("message") || cachedCustomer?.["message"] || "",
    customerGroups: parseCustomerGroups(),
    environment:
      search.get("environment") ||
      cachedCustomer?.["environment"] ||
      "mobile-itb",
    dialogIsOpen: false,
  };
};

function getTitle(options, settings) {
  let defaultTitle = JSON.parse(settings.title)[
    options.default_label_language || settings.languages
  ];
  if (!defaultTitle) {
    defaultTitle = JSON.parse(settings.title)[settings.languages];
  }
  return defaultTitle;
}

export default function App() {
  const search = useQuery();
  const { settings, isReady } = useSettings();
  const [loadingSettings, setLoadingSettings] = useState(true);
  const token = search.get("token");
  const logLevel = search.get("log-level") as log.LogLevelDesc;
  const [eqpProcessed, setEqpProcessed] = useState("notProcessed");
  const [options, setOptions] = useState(
    JSON.parse(search.get("options") || "{}")
  );
  useEffect(() => {
    document.getElementById("custom_css")?.remove();
    document.head.insertAdjacentHTML(
      "beforeend",
      `<style id="custom_css">${window?.["settings_env"]?.CUSTOM_CSS}</style>`
    );
  }, []);

  useEffect(() => {
    if (isReady) {
      if (token && token !== "undefined") {
        if (settings.token !== token) {
          console.error("Invalid token");
          return;
        }
        setOptions({
          ...settings,
          ...options,
          title: getTitle(options, settings),
        });
        setLoadingSettings(false);
      } else {
        setLoadingSettings(false);
      }
    }
  }, [isReady]);

  const [customer, setCustomer] = useState(initializeCustomer(search, options));
  // eqp (encrypted_query_params) is a hash that contains values which we should use
  // to override existing query params
  if (eqpProcessed === "notProcessed") {
    if (!search.get("eqp")) {
      setEqpProcessed("processed");
      return;
    }
    setEqpProcessed("processing");
    axios
      .get(
        `${window?.["app_env"].REACT_APP_BOT_API_URL}/decrypt_eqp/${search.get(
          "eqp"
        )}`
      )
      .then((result) => {
        const params = new URLSearchParams(result.data);
        setCustomer({
          ...customer,
          code: params.get("code") || customer.code,
          customerFullName:
            params.get("customerFullName") || customer.customerFullName,
          // override other query_params if needed
        });
        setEqpProcessed("processed");
      });
  }

  return eqpProcessed === "processed" && !loadingSettings ? (
    <CwcProvider
      botApiUrl=''
      customer={customer}
      logLevel={logLevel}
      options={options}
    >
      <ChatWidget />
    </CwcProvider>
  ) : null;
}
