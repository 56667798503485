import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

const ADMIN_API_BASE_URL = window?.["app_env"].REACT_APP_ADMIN_API_URL || "";

const SettingsContext = createContext({
  settings: null,
  isReady: false,
});

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    let url = ADMIN_API_BASE_URL;
    if (!url.endsWith(".json")) {
      url = url + "/api/v1/settings";
    }
    axios
      .get(url)
      .then(({ data }) => {
        setSettings(data);
      })
      .catch((err) => {
        console.error(err);
        setSettings({
          enabled: true,
        });
      });
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        settings,
        isReady: !!settings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  return useContext(SettingsContext);
};
