import React, { useState } from "react";
import format from "date-fns/format";

import { Carousel as CarouselI } from "types";
import arrowSVG from "./arrowSVG";
import { constructActions } from "../../messageActions";
import "./styles.scss";
import { AnyFunction } from "utils/types";

type Props = {
  carousel: CarouselI;
  showTimeStamp: boolean;
  sendMessage: AnyFunction;
};

function Carousel({ carousel, showTimeStamp, sendMessage }: Props) {
  const [currentItem, setCurrentItem] = useState(0);
  const setLocation = (uri: string) => {
    window.parent.location.href = uri;
  };
  const showNewItem = (newItem) => {
    setCurrentItem(newItem);
    document.getElementById("rcw-carousel-wrapper").scrollTo({
      top: 0,
      left: newItem * 350,
      behavior: "smooth",
    });
  };

  return (
    <div className="rcw-carousel">
      <div id="rcw-carousel-wrapper" className="rcw-carousel-wrapper">
        <div className="rcw-carousel-content">
          {carousel.items
            ? carousel.items.map((item, index) => (
                <div
                  id={`rcw-carousel-item-${index}`}
                  className="rcw-carousel-item"
                  key={index}
                  style={{
                    borderRadius:
                      index === 0
                        ? "14px 0px 0px 14px"
                        : index === carousel.items.length - 1
                        ? "0px 14px 14px 0px"
                        : "0px",
                  }}
                >
                  <img className="rcw-carousel-item-img" src={item.mediaUrl} />
                  <p className="rcw-carousel-item-title">{item.title}</p>
                  <p className="rcw-carousel-item-description">
                    {item.description}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      height: 106,
                    }}
                  >
                    {item.actions && item.actions.length > 0
                      ? constructActions(
                          item.actions.slice(0, 2),
                          sendMessage,
                          setLocation
                        )
                      : ""}
                  </div>
                </div>
              ))
            : ""}
        </div>
      </div>
      {currentItem !== 0 ? (
        <div
          className="rcw-carousel-arrow"
          onClick={() => showNewItem(currentItem - 1)}
        >
          {arrowSVG()}
        </div>
      ) : (
        ""
      )}
      {currentItem !== carousel.items.length - 1 ? (
        <div
          className="rcw-carousel-arrow right"
          onClick={() => showNewItem(currentItem + 1)}
        >
          {arrowSVG()}
        </div>
      ) : (
        ""
      )}
      {showTimeStamp && (
        <span className="rcw-timestamp">
          {format(carousel.timestamp, "hh:mm")}
        </span>
      )}
    </div>
  );
}

export default Carousel;
