import * as React from "react";
import * as ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "App";

import log from "loglevel";

log.setDefaultLevel("info");

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_ENV) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        startTransactionOnLocationChange: false,
        startTransactionOnPageLoad: false,
      }),
    ],
    ignoreErrors: ["Non-Error promise rejection captured"],
    environment: "chat_frontend_" + process.env.REACT_APP_SENTRY_ENV,
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById("chat-web-sdk"));
