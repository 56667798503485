import React from "react";

export default function () {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.20002 7.00002L6.60002 9.00002L9.80002 5.00002M7.00002 12.6C6.26462 12.6 5.53642 12.4552 4.857 12.1737C4.17757 11.8923 3.56023 11.4798 3.04023 10.9598C2.52022 10.4398 2.10773 9.82248 1.8263 9.14305C1.54487 8.46363 1.40002 7.73543 1.40002 7.00002C1.40002 6.26462 1.54487 5.53642 1.8263 4.857C2.10773 4.17757 2.52022 3.56023 3.04023 3.04023C3.56023 2.52022 4.17757 2.10773 4.857 1.8263C5.53642 1.54487 6.26462 1.40002 7.00002 1.40002C8.48524 1.40002 9.90962 1.99002 10.9598 3.04023C12.01 4.09043 12.6 5.51481 12.6 7.00002C12.6 8.48524 12.01 9.90962 10.9598 10.9598C9.90962 12.01 8.48524 12.6 7.00002 12.6Z"
        stroke="#40569E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
