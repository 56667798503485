import React from "react";
import "./style.scss";
// const close = require("assets/clear-button.svg") as string;

type Props = {
  title: string;
  titleCenter?: any;
  titleRight?: any;
  onTitleRightClicked?: any;
  subtitle: string;
  toggleChat: () => void;
  showCloseButton: boolean;
  titleAvatar?: string;
};

function Header({
  title,
  titleCenter,
  titleRight,
  onTitleRightClicked,
  // subtitle,
  // toggleChat,
  // showCloseButton,
  titleAvatar,
}: Props) {
  return (
    <div className="rcw-header">
      {/*{showCloseButton &&
      {/*  <button className="rcw-close-button" onClick={toggleChat}>*/}
      {/*    <img src={close} className="rcw-close" alt="close" />*/}
      {/*  </button>*/}
      {/*}*/}
      <h4 className="rcw-title">
        <span className="rcw-title__col rcw-title__col--left">
          {titleAvatar && (
            <img src={titleAvatar} className="avatar" alt="profile" />
          )}
          {title}
        </span>
        {titleCenter && (
          <span className="rcw-title__col rcw-title__col--center">
            {titleCenter}
          </span>
        )}
        {titleRight && (
          <span
            className="rcw-title__col rcw-title__col--right"
            onClick={() => onTitleRightClicked()}
          >
            {titleRight}
          </span>
        )}
      </h4>
      {/*<span>{subtitle}</span>*/}
    </div>
  );
}

export default Header;
