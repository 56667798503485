import React, {useRef, useEffect, useState} from "react";
import {AutoComplete} from "antd";
import {useSender} from "cwc-provider";
import "./style.scss";


type Props = {
  placeholder: string;
  disabledInput: boolean;
  autofocus: boolean;
  sendMessage: (event: any) => void;
  buttonAlt: string;
  onTextInputChange?: (event: any) => void;
  showChat: boolean;
  colors: any;
  labels: any[];
  hide: boolean;
};

const ASICE_HEX_HEADER = "504b34"

function readFileHeader(file: File, callback: (header: string) => void) {
  const fileReader = new FileReader();
  const blob = file.slice(0, 4);
  fileReader.onloadend = function (e) {
    const bytes = new Uint8Array(e.target.result as ArrayBuffer);
    let header = "";
    for (let i = 0; i < bytes.length; i++) {
      header += bytes[i].toString(16);
    }
    callback(header);
  };
  fileReader.readAsArrayBuffer(blob);
}

function Sender({
                  colors,
                  sendMessage,
                  placeholder,
                  disabledInput,
                  autofocus,
                  onTextInputChange,
                  showChat,
                  hide,
                }: Props) {
  const sender = useSender();
  const [value, setValue] = useState("");
  const [labelsToShow, setLabelsToShow] = useState([]);
  // onst showChat = useSelector((state: GlobalState) => state.behavior.showChat);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    sender.setInputReference(inputRef.current);
    if (!inputRef.current) {
      return;
    }
    inputRef.current.oninput = function () {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = inputRef.current.scrollHeight + "px";
    };
  }, [inputRef.current]);
  useEffect(() => {
    sender.setFileInputRef(fileInputRef.current);
  }, [fileInputRef.current]);
  useEffect(() => {
    if (showChat) inputRef.current?.focus();
  }, [showChat]);

  const onChange = (input: string) => {
    setValue(input);
    onTextInputChange?.(input);
  };

  const uploadIfAsice = (file: File) => {
    readFileHeader(file, (header) => {
      if (header === ASICE_HEX_HEADER) {
        const blob = file.slice(0, file.size, "application/vnd.etsi.asic-e+zip");
        const newFile = new File([blob], file.name, {type: "application/vnd.etsi.asic-e+zip"});
        sendMessage({
          target: {
            message: {
              file: newFile,
              value: "",
              type: "file",
            },
          },
          preventDefault: () => null
        });
      }
    });
  }

  const AttachmentIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="22"
      height="22"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 20 20"
    >
      <path
        fill="#b2b2b2"
        d="M5.602 19.8c-1.293 0-2.504-.555-3.378-1.44c-1.695-1.716-2.167-4.711.209-7.116l9.748-9.87c.988-1 2.245-1.387 3.448-1.06c1.183.32 2.151 1.301 2.468 2.498c.322 1.22-.059 2.493-1.046 3.493l-9.323 9.44c-.532.539-1.134.858-1.738.922c-.599.064-1.17-.13-1.57-.535c-.724-.736-.828-2.117.378-3.337l6.548-6.63c.269-.272.705-.272.974 0s.269.714 0 .986l-6.549 6.631c-.566.572-.618 1.119-.377 1.364c.106.106.266.155.451.134c.283-.029.606-.216.909-.521l9.323-9.439c.64-.648.885-1.41.69-2.145a2.162 2.162 0 0 0-1.493-1.513c-.726-.197-1.48.052-2.12.7l-9.748 9.87c-1.816 1.839-1.381 3.956-.209 5.143c1.173 1.187 3.262 1.629 5.079-.212l9.748-9.87a.683.683 0 0 1 .974 0a.704.704 0 0 1 0 .987L9.25 18.15c-1.149 1.162-2.436 1.65-3.648 1.65z"
      />
    </svg>
  );
  const SendIcon = (
    <button type="submit" className="rcw-send">
      <svg
        width="26"
        height="26"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 2L10 13"
          stroke={`${colors?.accentColor || "white"}`}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 2L14 22L10 13L1 9L21 2Z"
          stroke={`${colors?.accentColor || "white"}`}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );

  return (
    <form
      style={hide ? {display: "None"} : {}}
      className="rcw-sender"
      id="submit-user-input-form"
      onSubmit={(e: any) => {
        let rc_select_textarea_0 = inputRef.current;
        rc_select_textarea_0.style.height = "38px";
        sendMessage(e);
        setValue("");
        setLabelsToShow([]);
      }}
    >
      <AutoComplete
        options={value.startsWith("!") ? labelsToShow : []}
        onSelect={(option) => setValue(`!${option}`)}
        onChange={onChange}
        style={{width: "100%"}}
        onInputKeyDown={(e) => {
          if (e.key === "Enter") {
            const isSearchListOpen =
              document.getElementsByClassName("ant-select-open").length > 0;
            if (!isSearchListOpen) {
              e.preventDefault();
              // hack to trigger form onsubmit https://stackoverflow.com/a/18072246
              const form = document.getElementById("submit-user-input-form");
              const button = form.ownerDocument.createElement("input");
              button.style.display = "none";
              button.type = "submit";
              form.appendChild(button).click();
              form.removeChild(button);
            }
          }
        }}
        value={value}
      >
        <span className="ant-select-selection-search">
          <span
            className="ant-input-group-wrapper ant-input-group-wrapper-lg ant-input-search ant-input-search-large ant-input-search-with-button ant-select-selection-search-input">
            <span className="ant-input-wrapper ant-input-group">
              <div style={{position: "relative"}}>
                <textarea
                  autoComplete="off"
                  name="message"
                  disabled={disabledInput}
                  placeholder={placeholder}
                  value={value}
                  onChange={() => {
                  }}
                  ref={inputRef}
                  autoFocus={autofocus}
                  rows={1}
                  role="combobox"
                  aria-haspopup="listbox"
                  aria-owns="rc_select_0_list"
                  aria-autocomplete="list"
                  aria-controls="rc_select_0_list"
                  aria-activedescendant="rc_select_0_list_0"
                  className="ant-input ant-input-lg ant-input-borderless"
                  id="rc_select_textarea_0"
                  aria-expanded="false"
                  dir="auto"
                />
                {!value && (
                  <>
                    <input
                      ref={fileInputRef}
                      onChange={(e) => {
                        e.stopPropagation();
                        const file = e.target.files[0];
                        e.target.value = null;
                        if (file) {
                          if (
                            ![
                              "image/png",
                              "image/jpeg",
                              "application/pdf",
                              "image/gif",
                              "application/vnd.etsi.asic-e+zip",
                              "text/plain"
                            ].includes(file.type)
                          ) {
                            uploadIfAsice(file);
                            return;
                          }

                          if (file.size > 9999999999) return;
                          sendMessage({
                            target: {
                              message: {
                                file,
                                value: "",
                                type: "file",
                              },
                            },
                            preventDefault: () => {
                            },
                          });
                        }
                      }}
                      style={{display: "none"}}
                      id="inputFile"
                      name="inputFile"
                      type="file"
                      accept=".jpg,.jpeg,.png,.pdf,.gif,.asice,.txt"
                    />
                    <label
                      htmlFor="inputFile"
                      style={{
                        position: "absolute",
                        right: 8,
                        top: 13,
                        cursor: "pointer",
                        zIndex: 9999,
                      }}
                    >
                      {AttachmentIcon}
                    </label>
                  </>
                )}
              </div>
              <span id="send-btn-wrapper" className="ant-input-group-addon">
                {SendIcon}
              </span>
            </span>
          </span>
        </span>
      </AutoComplete>
    </form>
  );
}

export default Sender;
