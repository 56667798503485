import React from "react";

import { useCwcChat, useCwcIframe } from "cwc-provider";
import Widget from "components/Widget";
import linkImg from "../../assets/link.svg";
import chatAddImg from "../../assets/add-chat.svg";
import chatMinimizeImg from "../../assets/minimize.svg";
import feedbackImg from "../../assets/feedback.svg";

const ElisaChatWidget = () => {
  const {
    sendMessage,
    messages,
    resetSession,
    customerData,
    user,
    botApiUrl
  } = useCwcChat();

  const {
    displayImage,
    copyToClipboard,
    inIframe,
    toggleParentWindow,
    options: { mode, exclude_launch, recent_message_threshold },
  } = useCwcIframe();

  const handleNewUserMessage = (newMessage, inputNodeId = null) => {
    sendMessage({
      type: "text",
      text: newMessage,
      input_node_id: inputNodeId,
      metadata: {},
      actions: [],
    });
  };

  const handlePostbackMessage = (payload) => {
    sendMessage({
      type: "postback",
      payload: payload,
      text: payload,
      metadata: {},
      actions: [],
    });
  };

  const handleFileMessage = (file) => {
    sendMessage({
      type: "file",
      file,
      metadata: {},
      actions: [],
    });
  };

  const handleQuickButtonClicked = () => {};
  const handleSubmit = () => {};

  function buildChatLink(userId: string | null): string | null {
    if (!userId) {
      return null;
    }
    return `${botApiUrl}/chat/${userId}`;
  }

  const chatLink = buildChatLink(user?.user_id);

  function buildChatFeedbackUrl(userId: string): string {
    const chatUrl = buildChatLink(userId);
    const queryParams = new URLSearchParams({ chatUrl });
    return `https://andmed.elisa.ee/chatbot/feedback?${queryParams.toString()}`;
  }

  function _openFeedback(e) {
    e.preventDefault();
    const chatUrl = buildChatFeedbackUrl(user.user_id);
    window.open(chatUrl, "_blank").focus();
  }

  return (
    <Widget
      title=""
      titleCenter={
        customerData?.customerId && parseInt(customerData?.customerId) !== 0 ? (
          <span>
            ID <strong>{customerData?.customerId}</strong>
          </span>
        ) : (
          "Vali kliendinumber"
        )
      }
      colors={{
        brandColor: "#ffffff",
        accentColor: "#0618A8",
        secondaryAccentColor: "#0618A8",
      }}
      screenMode={"mobile"}
      titleRight={
        <span>
          <span>
            <span className="icon-link-header-wrapper">
              <a
                title="Tagasiside"
                href="#"
                onClick={_openFeedback}
                className="icon-link"
              >
                <img src={feedbackImg} alt="Feedback link" />
              </a>
            </span>
            <span className="icon-link-header-wrapper">
              <a
                title="Kopeeri vestluse logi link"
                href={chatLink}
                target="_blank"
                rel="noopener noreferrer"
                className="icon-link"
              >
                <img src={linkImg} alt="Kopeeri link" />
              </a>
            </span>
            <span className="icon-link-header-wrapper">
              <a
                title="Uus chat"
                href="#"
                onClick={resetSession}
                className="icon-link"
              >
                <img src={chatAddImg} alt="Reset chat" />
              </a>
            </span>
          </span>
          {mode !== "desktop" && inIframe && !exclude_launch && (
            <a
              href="#"
              onClick={() => toggleParentWindow()}
              className="icon-link"
            >
              <img src={chatMinimizeImg} alt="Close window" />
            </a>
          )}
        </span>
      }
      toggleParentWindow={toggleParentWindow}
      hideLauncher={exclude_launch}
      subtitle="Elisa virtual assitant"
      titleAvatar={require("./assets/annika.svg")}
      senderPlaceHolder="Kirjuta siia kliendi küsimus..."
      handleNewUserMessage={handleNewUserMessage}
      handlePostbackMessage={handlePostbackMessage}
      handleQuickButtonClicked={handleQuickButtonClicked}
      handleFileMessage={handleFileMessage}
      displayImage={displayImage}
      imagePreview
      handleSubmit={handleSubmit}
      messages={messages}
      dissableInput={false}
      recentMessageThreshold={recent_message_threshold}
    />
  );
};

export default ElisaChatWidget;
