import React, {
  useEffect,
  useRef,
  // ElementRef,
  // ImgHTMLAttributes,
  // MouseEvent,
} from "react";
import { scrollToBottom } from "utils/messages";

// import Loader from "./components/Loader";
import "./styles.scss";
import { AnyFunction } from "utils/types";
import {
  getCarouselComponent,
  getMessageComponent,
  getTableComponent,
} from "./messageComponents";

type Props = {
  showTimeStamp: boolean;
  profileAvatar?: string;
  messages: any[];
  sendMessage: AnyFunction;
  recentMessageThreshold: number;
  displayImage?: AnyFunction;
};

const invisibleMessageTypes = ["postback", "flow_ended"];

const getFirstAndLastResponseIndex = (messages) => {
  let result = {
    first: messages.length,
    last: -1,
  }
  for (let i = 0; i < messages.length; i++) {
    const m = messages[i];
    if (!invisibleMessageTypes.includes(m.type) && messages[i].role === "appMaker") {
      if (i < result.first) {
        result.first = i;
      }
      if (i > result.last) {
        result.last = i;
      }
    }
  }
  return result;
}

function Messages({
  // profileAvatar,
  showTimeStamp,
  messages,
  sendMessage,
  recentMessageThreshold,
  displayImage,
}: Props) {
  const messageRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    scrollToBottom(messageRef.current);
  }, [messages]);

  const getComponentToRender = (message: any, sharpCorner?: "top" | "bottom" | "both") => {
    if (message.type === "carousel") {
      return getCarouselComponent(message, showTimeStamp, sendMessage);
    } else if (message.type === "table") {
      return getTableComponent(message, showTimeStamp);
    } else {
      return getMessageComponent(
        message,
        showTimeStamp,
        sendMessage,
        recentMessageThreshold,
        displayImage,
        sharpCorner
      );
    }
  };

  // TODO: Fix this function or change to move the avatar to last message from response
  // const shouldRenderAvatar = (message: Message, index: number) => {
  //   const previousMessage = messages[index - 1];
  //   if (message.showAvatar && previousMessage.showAvatar) {
  //     // this functionality is absent --> dispatch(hideAvatar(index));
  //   }
  // }


  const messagesCount = messages.filter((m) => m.type !== "flow_ended").length;
  const { first, last } = getFirstAndLastResponseIndex(messages);

  return (
    <div id="messages" className="rcw-messages-container" ref={messageRef}>
      <div className="rcw-messages-scrollable-container">
        {messages
          ?.filter((message) => !invisibleMessageTypes.includes(message.type))
          .map((message, index) => (
            <div
              key={`${index}`}
              className={
                index > 0 && messages[index - 1].role != message.role
                  ? "direction-change"
                  : ""
              }
            >
              {/* key={`${index}-${format(message.timestamp, 'hh:mm')}*/}
              {/*{profileAvatar &&*/}
              {/*  message.showAvatar &&*/}
              {/*  <img src={profileAvatar} className="rcw-avatar" alt="profile" />*/}
              {/*}*/}
              {/* {getComponentToRender({
                ...message,
                isLastMessage: messages
                  .slice(index)
                  .find((m) => m.type === "flow_ended")
                  ? false
                  : true,
              })} */}
              {getComponentToRender({
                ...message,
                isLastMessage: index + 1 === messagesCount,
              }, index === first ? "bottom" : index === last ? "top" : "both")}
            </div>
          ))}
        {/*Uncomment this when loader needed: <Loader typing={typing} />*/}
      </div>
    </div>
  );
}

export default Messages;
