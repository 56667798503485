import React from "react";

import { useCwcChat } from "cwc-provider";
import Header from "./components/Header";
import Messages from "./components/Messages";
import Sender from "./components/Sender";
import QuickButtons from "./components/QuickButtons";
import {SegmentOverlay, ConfirmSolutionOverlay} from "./components/Overlay";

import { AnyFunction } from "utils/types";

import "./style.scss";

type Props = {
  title: string;
  titleCenter?: any;
  titleRight?: any;
  onTitleRightClicked?: any;
  subtitle: string;
  senderPlaceHolder: string;
  showCloseButton: boolean;
  disabledInput: boolean;
  autofocus: boolean;
  className: string;
  sendMessage: AnyFunction;
  toggleChat: AnyFunction;
  profileAvatar?: string;
  titleAvatar?: string;
  // onQuickButtonClicked?: AnyFunction;
  onTextInputChange?: (event: any) => void;
  sendButtonAlt: string;
  showTimeStamp: boolean;
  messages: any[];
  showChat: boolean;
  screenMode: any;
  colors: any;
  hideLauncher: any;
  recentMessageThreshold: number;
  labels: any[];
  displayImage?: AnyFunction;
};


function SimpleConversation({
  displayImage,
  sendMessage,
  profileAvatar,
  showTimeStamp,
  messages,
  recentMessageThreshold,
}) {
  return <>
    <Messages
      displayImage={displayImage}
      sendMessage={sendMessage}
      profileAvatar={profileAvatar}
      showTimeStamp={showTimeStamp}
      messages={messages}
      recentMessageThreshold={recentMessageThreshold}
    />
    <QuickButtons /* onQuickButtonClicked={onQuickButtonClicked}*/ />
  </>
}

function Conversation({
  colors,
  title,
  titleCenter,
  titleRight,
  onTitleRightClicked,
  subtitle,
  senderPlaceHolder,
  showCloseButton,
  disabledInput,
  autofocus,
  className,
  sendMessage,
  toggleChat,
  profileAvatar,
  titleAvatar,
  // onQuickButtonClicked,
  onTextInputChange,
  sendButtonAlt,
  showTimeStamp,
  messages,
  showChat,
  screenMode,
  hideLauncher,
  recentMessageThreshold,
  labels,
  displayImage
}: Props) {
  const {
    segment,
    setSegment,
    language,
    labelTranslations,
    publishStartSolution,
    confirmSolutionData,
    clearConfirmSolutionData,
    skipAskingForConfirm
  } = useCwcChat();
  let toRender = <SimpleConversation
    displayImage={displayImage}
    sendMessage={sendMessage}
    profileAvatar={profileAvatar}
    showTimeStamp={showTimeStamp}
    messages={messages}
    recentMessageThreshold={recentMessageThreshold}
  />;

  if (segment === 'unknown') {
    const changeSegment = (segment: string) => {
      // when user confirms segment from chat bubble, no need to ask for diagram topic/label confirmation
      setSegment(segment);
      clearConfirmSolutionData();
      skipAskingForConfirm();
    }
    toRender = <SegmentOverlay currentLanguage={language} setSegment={changeSegment}/>
  } else if (confirmSolutionData) {
    if (labelTranslations) {
      const dialogCode = Array.isArray(confirmSolutionData.dialogCode) ?
        confirmSolutionData.dialogCode[0] : confirmSolutionData.dialogCode;
      const label = labelTranslations?.[dialogCode]?.[language || "et"] ?? dialogCode;
      toRender = <ConfirmSolutionOverlay currentLanguage={language} label={label}
                                         startSolution={() => publishStartSolution(confirmSolutionData)}
                                         clearConfirmSolutionLabel={clearConfirmSolutionData}/>
    }
  }
  return (
    <div
      className={`rcw-conversation-container ${className}${
        screenMode == "large" ? " large" : ""
      }`}
      style={hideLauncher ? { height: "100vh" } : {}}
      aria-live="polite"
    >
      <Header
        onTitleRightClicked={onTitleRightClicked}
        title={title}
        titleCenter={titleCenter}
        titleRight={titleRight}
        subtitle={subtitle}
        toggleChat={toggleChat}
        showCloseButton={showCloseButton}
        titleAvatar={titleAvatar}
      />
      {toRender}
      <Sender
        colors={colors}
        sendMessage={sendMessage}
        placeholder={senderPlaceHolder}
        disabledInput={disabledInput}
        autofocus={autofocus}
        onTextInputChange={onTextInputChange}
        buttonAlt={sendButtonAlt}
        showChat={showChat}
        labels={labels}
        hide={segment === 'unknown'}
      />
    </div>
  );
}

export default Conversation;
