import React, { useEffect, useRef } from "react";
import cn from "classnames";

import { AnyFunction } from "utils/types";
import { useAudio } from "cwc-provider";

import Conversation from "./components/Conversation";
// import Launcher from "./components/Launcher";
// import FullScreenPreview from "./components/FullScreenPreview";
import "./style.scss";

type Props = {
  title: string;
  titleAvatar?: string;
  titleCenter?: any;
  titleRight?: any;
  subtitle: string;
  hideLauncher?: any;
  chatOpen?: any;
  setChatOpen?: any;
  setAudioContext?: AnyFunction;
  onSendMessage: AnyFunction;
  onToggleConversation: AnyFunction;
  senderPlaceHolder: string;
  onQuickButtonClicked: AnyFunction;
  toggleParentWindow: AnyFunction;
  displayImage?: AnyFunction;
  profileAvatar?: string;
  showCloseButton: boolean;
  fullScreenMode: boolean;
  autofocus: boolean;
  customLauncher?: AnyFunction;
  onTextInputChange?: (event: any) => void;
  chatId: string;
  launcherOpenLabel: string;
  launcherCloseLabel: string;
  sendButtonAlt: string;
  showTimeStamp: boolean;
  imagePreview?: boolean;
  zoomStep?: number;
  messages: any[];
  showChat: boolean;
  visible: boolean;
  dissableInput: boolean;
  screenMode: any;
  colors: any;
  recentMessageThreshold: number;
  labels: any[];
};

function WidgetLayout({
  title,
  titleAvatar,
  titleCenter,
  titleRight,
  hideLauncher,
  setChatOpen,
  // toggleParentWindow,
  setAudioContext,
  displayImage,
  subtitle,
  onSendMessage,
  onToggleConversation,
  senderPlaceHolder,
  // onQuickButtonClicked,
  profileAvatar,
  showCloseButton,
  fullScreenMode,
  autofocus,
  // customLauncher,
  onTextInputChange,
  // chatId,
  // launcherOpenLabel,
  // launcherCloseLabel,
  sendButtonAlt,
  showTimeStamp,
  imagePreview,
  // zoomStep,
  messages,
  showChat,
  visible,
  dissableInput,
  screenMode,
  colors,
  recentMessageThreshold,
  labels,
}: Props) {
  // const dispatch = useDispatch();
  // const { dissableInput, showChat, visible } = useSelector((state: GlobalState) => ({
  //   showChat: state.behavior.showChat,
  //   dissableInput: state.behavior.disabledInput,
  //   visible: state.preview.visible,
  // }));
  const { isLoaded, load, audio, audiosQueue, notificationAudio } = useAudio();
  const messageRef = useRef<HTMLDivElement | null>(null);

  const onTitleRightClicked = () => {
    setChatOpen(false);
  };

  useEffect(() => {
    if (showChat) {
      messageRef.current = document.getElementById(
        "messages"
      ) as HTMLDivElement;
    }
    return () => {
      messageRef.current = null;
    };
  }, [showChat]);

  const eventHandle = (evt) => {
    if (evt.target && evt.target.className === "rcw-message-img") {
      // const {
      //   src,
      //   alt,
      //   naturalWidth,
      //   naturalHeight,
      // } = evt.target as HTMLImageElement;
      // const obj = {
      //   src: src,
      //   alt: alt,
      //   width: naturalWidth,
      //   height: naturalHeight,
      // };
      // dispatch(openFullscreenPreview(obj))
    }
  };

  /**
   * Previewer needs to prevent body scroll behavior when fullScreenMode is true
   */
  useEffect(() => {
    const target = messageRef?.current;
    if (imagePreview && showChat) {
      target?.addEventListener("click", eventHandle, false);
    }

    return () => {
      target?.removeEventListener("click", eventHandle);
    };
  }, [imagePreview, showChat]);

  useEffect(() => {
    document.body.setAttribute(
      "style",
      `overflow: ${visible || fullScreenMode ? "hidden" : "auto"}`
    );
  }, [fullScreenMode, visible]);
  useEffect(() => {
    if (isLoaded && setAudioContext) setAudioContext(audio, audiosQueue, notificationAudio);
  }, [isLoaded]);
  return (
    <div
      className={cn("rcw-widget-container", {
        "rcw-full-screen": fullScreenMode,
        "rcw-previewer": imagePreview,
      })}
      onClickCapture={() => {
        if (!isLoaded) load();
      }}
    >
      <Conversation
        colors={colors}
        title={title}
        titleCenter={titleCenter}
        titleRight={titleRight}
        onTitleRightClicked={onTitleRightClicked}
        subtitle={subtitle}
        sendMessage={onSendMessage}
        senderPlaceHolder={senderPlaceHolder}
        profileAvatar={profileAvatar}
        toggleChat={onToggleConversation}
        showCloseButton={showCloseButton}
        disabledInput={dissableInput}
        autofocus={autofocus}
        titleAvatar={titleAvatar}
        className={"active"}
        // onQuickButtonClicked={onQuickButtonClicked}
        onTextInputChange={onTextInputChange}
        sendButtonAlt={sendButtonAlt}
        showTimeStamp={showTimeStamp}
        messages={messages}
        showChat={showChat}
        screenMode={screenMode}
        hideLauncher={hideLauncher}
        recentMessageThreshold={recentMessageThreshold}
        labels={labels}
        displayImage={displayImage}
      />
    </div>
  );
}

export default WidgetLayout;
