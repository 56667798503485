import React, { useEffect } from "react";
import { ttlLocalStorage } from "../../utils/localStorage";

export function ClearMemory() {
  useEffect(() => {
    const origin = new URLSearchParams(window.location.search).get("origin");
    ttlLocalStorage.clear();
    window.parent.postMessage({
      event: "memoryCleared",
    }, origin);
  }, []);

  return <div style={{ display: 'none' }} />
}

export default ClearMemory;