import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ClearMemory from "./components/ClearMemory";
import ElisaApp from "./customers/elisa";
import MindtitanApp from "./customers/mindtitan";
import CustomApp from "./customers/custom";
import Notification from "./customers/custom/notification";
import Launcher from "./customers/custom/launcher";
import PostiveMindPractice from "./customers/positivemindpractice";
import MindtitanFull from "./customers/mindtitan-full";
import { SettingsProvider, useSettings } from "./utils/SettingsProvider";

function AppRoutes() {
  const [enabled, setEnabled] = useState(false);
  const {
    isReady,
    settings
  } = useSettings();

  useEffect(() => {
    if (isReady) {
      setEnabled(settings.enabled);
    }
  }, [isReady])

  return (
    <Router>
      <Switch>
        <Route path="/clear_memory">
          <ClearMemory />
        </Route>
        <Route path="/mindtitan">
          <MindtitanApp />
        </Route>
        <Route path="/positivemindpractice">
          <PostiveMindPractice />
        </Route>
        <Route path="/notification">
          <Notification />
        </Route>
        <Route path="/launcher">
          <Launcher />
        </Route>
        <Route path="/custom">{enabled ? <CustomApp /> : null}</Route>
        <Route path="/custom_enabled">
          <CustomApp />
        </Route>
        <Route path="/client_chat">
          <MindtitanFull />
        </Route>
        <Route path="/">
          <ElisaApp />
        </Route>
      </Switch>
    </Router>
  )
}

export default function App() {
  return (
    <SettingsProvider>
      <AppRoutes />
    </SettingsProvider>
  );
}
