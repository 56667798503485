import React, { useEffect } from "react";
import log from "loglevel";

import { CwcProvider, useQuery } from "cwc-provider";
import ElisaChatWidget from "./widget";
import {v4 as uuidv4} from 'uuid';
import { addLuminance } from "../../utils/colors";

export default function App() {
  const search = useQuery();
  const logLevel = search.get("log-level") as log.LogLevelDesc;

  const newUserUUID = uuidv4();
  const customer = {
    code: search.get("code") || newUserUUID.toString(),
    externalToken: search.get("externalToken"),
    customerId: search.get("customerId") || "0",
    secure: search.get("secure") || "false",
    segment: search.get("segment") || "Anonymous",
    customer_proxy: search.get("customer_proxy") || "",
    customerFullName:
      search.get("customerFullName") || "klient ".concat(newUserUUID.toString().split('-')[0]),
  };

  useEffect(() => {
    const style = document.createElement("style");
    style.setAttribute("id", "widget-tmp-styles");
    style.innerText = `
      .rcw-response .rcw-message-action-button {
        background-color: #FFFFFF !important;
        color: #273645;
      }
      
      .rcw-response .rcw-message-action-button:hover {
        color: ${addLuminance("#273645", 12)} !important;
        border: 2px solid ${addLuminance("#273645", 12)} !important;
      }
    `;
    document.head.appendChild(style);
  }, []);

  return (
    <CwcProvider
      botApiUrl={window?.["app_env"].REACT_APP_BOT_API_URL || ''}
      customer={customer}
      logLevel={logLevel}
      options={JSON.parse(search.get("options") || "{}")}
    >
      <ElisaChatWidget />
    </CwcProvider>
  );
}
